import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { db, storage } from "../../_config/firebaseConfig"; // Import your Firebase Firestore instance here
import {
  GetSingleArgs,
  PageAndSearchArgs,
  PagedData,
  Product,
} from "../../interfaces";
import { StateTopicEnum } from "../../enums";
import { publish } from "../../hooks";
import Offers from "../../interfaces/Offers/Offers";
import {
  deleteObject,
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytes,
} from "firebase/storage";

interface OfferService {
  getOfferByPage: (args: PageAndSearchArgs) => Promise<PagedData<Offers>>;
  getOffers: () => Promise<any>;
  getOfferSingle: (args: GetSingleArgs) => Promise<any>;
  createOffer: (data: Offers, imageFile: File | null) => Promise<any>;
  updateOffer: (
    id: string,
    data: Offers,
    imageFile: File | null
  ) => Promise<any>;
  deleteOffer: (id?: string) => Promise<void>;
  getProducts: () => Promise<any>;
}
const offerRef = collection(db, "Offers");
const service: OfferService = {
  getOfferByPage: async ({ search, pageNo }: PageAndSearchArgs) => {
    const pageSize = 13;
    try {
      const querySnapshot = await getDocs(offerRef);

      const offers: Offers[] = [];

      querySnapshot.forEach((doc) => {
        const offerData = doc.data();
        offerData.id = doc.id;

        offers.push({
          id: offerData.id,
          offerName: offerData.offerName,
          discountAmount: offerData.discountAmount,
          includedPackages: offerData.includedPackages,
          expirationDate: offerData.expirationDate,
          status: offerData.status,
        });
      });
      return {
        pageNo: pageNo,
        pageSize: pageSize,
        pageCount: 1,
        itemCount: offers.length,
        data: offers,
      };
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  getOffers: async () => {
    try {
      const querySnapshot = await getDocs(offerRef);

      const offers: Offers[] = [];

      querySnapshot.forEach((doc) => {
        const offerData = doc.data();
        offerData.id = doc.id;

        offers.push({
          id: offerData.id,
          offerName: offerData.offerName,
          discountAmount: offerData.discountAmount,
          includedPackages: offerData.includedPackages,
          expirationDate: offerData.expirationDate,
          status: offerData.status,
        });
      });
      return offers;
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  getOfferSingle: async ({ id }: GetSingleArgs) => {
    try {
      const offerRef = doc(db, "Offers", id);
      const docSnapshot = await getDoc(offerRef);

      if (docSnapshot.exists()) {
        const offerData = docSnapshot.data();
        offerData.id = docSnapshot.id;
        return offerData as Offers;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  createOffer: async (data: Offers, imageFile: File | null) => {
    try {
      if (imageFile) {
        const storageRef = ref(storage, `bannerImages/${imageFile.name}`);

        // Upload the image to Firebase Storage
        await uploadBytes(storageRef, imageFile);
        const imageUrl = await getDownloadURL(storageRef);

        // Add the image URL to the product data
        data.image_url = imageUrl;
      }
      const docRef = await addDoc(offerRef, data);
      publish(StateTopicEnum.ReloadUpdate, null);
      return { success: true, id: docRef.id };
    } catch (error) {
      console.error("Firebase error:", error);
      return { success: false, error: error };
    }
  },
  updateOffer: async (id: string, data: Offers, image: File | null) => {
    try {
      const offerRef = doc(db, "Offers", id);
      const offerSnapshot = await getDoc(offerRef);
      const offerData = offerSnapshot.data();
      if (image) {
        // If a new image is provided, delete the previous image from Firebase Storage
        const previousImageUrl = offerData?.image;
        if (previousImageUrl) {
          try {
            // Construct a reference to the previous image
            const previousImageRef = ref(storage, previousImageUrl);

            // Check if the previous image exists before attempting to delete it
            const imageExists = await getMetadata(previousImageRef)
              .then(() => true)
              .catch(() => false);

            if (imageExists) {
              // Delete the previous image
              await deleteObject(previousImageRef);
              console.log("Previous image deleted successfully.");
            } else {
              console.log("Previous image does not exist. No deletion needed.");
            }
          } catch (deleteError) {
            console.error("Error deleting previous image:", deleteError);
          }
        }

        // Upload the new image to Firebase Storage with a new path
        const storageRef = ref(storage, `bannerImages/${image.name}`);
        await uploadBytes(storageRef, image);
        const imageUrl = await getDownloadURL(storageRef);

        // Update the product data with the new image URL
        data.image_url = imageUrl;
      }
      await setDoc(offerRef, data);
      publish(StateTopicEnum.ReloadUpdate, null);
      return { success: true };
    } catch (error) {
      console.error("Firebase error:", error);
      return { success: false, error: error };
    }
  },
  deleteOffer: async (id?: string): Promise<void> => {
    try {
      if (!id) {
        throw new Error("Offers ID is missing.");
      }

      const offerRef = doc(db, "Offers", id);

      await deleteDoc(offerRef);
    } catch (error) {
      console.error("Firebase error:", error);
    }
  },
  getProducts: async () => {
    try {
      const offerRef = collection(db, "Products");
      const querySnapshot = await getDocs(offerRef);

      const products: Product[] = [];

      querySnapshot.forEach((doc) => {
        const offerData = doc.data();
        offerData.id = doc.id;

        products.push({
          id: offerData.id,
          title: offerData.title,
        });
      });
      return products;
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
};

export default service;

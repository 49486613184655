import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import "./_styles.scss";
import Review from "../../../interfaces/Reviews/Review";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";

interface GridProps {
  rows?: Review[];
  onSave: (row: any, index: number) => void;
}

function RatingStars({ rating }: any) {
  const totalStars = 5;
  const filledStars = Math.min(rating, totalStars);

  return (
    <div className="rating-stars">
      {[...Array(filledStars)].map((_, index) => (
        <FontAwesomeIcon icon={solidStar} key={index} className="star-filled" />
      ))}
      {[...Array(totalStars - filledStars)].map((_, index) => (
        <FontAwesomeIcon
          icon={regularStar}
          key={filledStars + index}
          className="star-empty"
        />
      ))}
    </div>
  );
}
interface RowProps {
  row: Review;
  index: number;
  selected: boolean;
  onSelect: (row: any, index: number) => void;
  onSave: (row: any, index: number) => void;
}
function ReviewCard({ row, index, onSave, selected, onSelect }: RowProps) {
  const [isActive, setIsActive] = useState(row.status === "active");

  const handleCheckboxChange = () => {
    const updatedStatus = isActive ? "inactive" : "active";
    setIsActive(!isActive);
    const updatedRow = { ...row, status: updatedStatus };
    onSelect(updatedRow, index);
    onSave(updatedRow, index);
  };

  return (
    <div className="review-card">
      <div className="top-section">
        <div className="date">{row?.date ?? ""}</div>
        <div className="checkbox">
          <input
            type="checkbox"
            checked={isActive}
            onChange={handleCheckboxChange}
          />
        </div>
      </div>
      <div className="middle-section">
        <div className="heading">{row?.name ?? ""}</div>
        <div className="sub-heading">{row?.title ?? ""}</div>
        <div className="review-text">"{row?.comment ?? ""}"</div>
      </div>
      <div className="bottom-section">
        <RatingStars rating={row?.rating || 0} />
      </div>
    </div>
  );
}
export default function ReviewDataGridTable({ rows, onSave }: GridProps) {
  const [selected, setSelected] = useState<number>();
  return (
    <div className="review-grid-container">
      {!!rows &&
        rows.map((row: any, index: any) => (
          <ReviewCard
            key={`review-card-${index}`}
            row={row}
            selected={selected === index}
            index={index}
            onSelect={(r, i) => {
              setSelected(i);
            }}
            onSave={(r, i) => {
              setSelected(undefined);
              onSave(r, i);
            }}
          />
        ))}
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import Form from "../../../components/form/Form";
import { TextField } from "../../../components/fields";
import { NavigableComponent } from "../../../interfaces";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { FaArrowLeft, FaEdit, FaTrash } from "react-icons/fa";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { routes } from "../../../_config";
import { useNavigate, useParams } from "react-router-dom";
import { publish } from "../../../hooks";
import Offers from "../../../interfaces/Offers/Offers";
import service from "../../../services/Offers/offerService";
import "./_styles.scss";
import { Card, CardContent } from "@mui/material";
interface ViewPackageDataProps {
  offer: Offers;
}

export default function ViewOfferItem({
  setToolbarConfig,
  offer,
}: ViewPackageDataProps & NavigableComponent) {
  const { offerId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [];

    toolbarItems = [
      {
        label: "Back",
        icon: FaArrowLeft,
        primary: true,
        raised: true,
        onClick: () => {
          navigate(routes.offer.go(), {
            replace: false,
          });
        },
      },
      "|",
      {
        label: "Edit",
        icon: FaEdit,
        primary: true,
        raised: true,
        onClick: handleEdit,
      },
      "|",
      { label: "Delete", icon: FaTrash, onClick: handleDelete },
    ];

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.offer.go(offerId),
    });
  }, []);

  const handleEdit = () => {
    navigate(routes.offerItem.go(offerId) + "?mode=edit", {
      replace: false,
    });
  };

  const handleDelete = () => {
    showAlert({
      content: `Deleting the offer ${offer?.offerName} now! Are you sure?`,
      options: {
        type: AlertTypeEnum.Error,
        actions: [
          {
            text: "Yes",
            primary: true,
            onClick: () => {
              hideAlert();
              service.deleteOffer(offerId ?? "");
              navigate(`${routes.offer.go()}`);
              publish(StateTopicEnum.CurrentItem, null);
            },
          },
          { text: "No" },
        ],
      },
    });
  };

  return (
    <>
      <Form title="Offer primary details">
        <TextField label="Offer Name" readOnly value={offer?.offerName} />
        <TextField
          label="Discount Amount"
          readOnly
          value={offer?.discountAmount}
        />
        <TextField
          label="Expiration Date"
          readOnly
          value={offer?.expirationDate}
        />
        <TextField label="Status" readOnly value={offer?.status} />
        <div
          style={{
            marginTop: "24px",
            marginBottom: "24px",
          }}
        >
          <Card sx={{ width: 1080, padding: "24px" }}>
            <CardContent
              style={{
                height: "200px",
                background: `url(${offer.image_url})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
              }}
            ></CardContent>
          </Card>
        </div>
        <div className="package-offer-grid-container">
          {offer.includedPackages?.map((e) => (
            <div className="package-offer-card">
              <div className="top-section">
                <div className="date">{e?.tag ?? ""}</div>
              </div>
              <div className="middle-section">
                <div className="heading">{e?.title ?? ""}</div>
                <div className="sub-heading">{e?.description ?? ""}</div>
                <div className="package-offer-text">
                  Price: {e?.price ?? ""}
                </div>
              </div>
              <div className="bottom-section">{e.duration}</div>
            </div>
          ))}
        </div>
      </Form>
    </>
  );
}

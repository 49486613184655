import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faFile,
  faFileArchive,
  faFileAudio,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileText,
  faFileVideo,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons";

export default function getFileType(fileName: string): {
  fileClass: string;
  fileIcon: IconProp;
} {
  let fileClass: string = "generic-file";
  let fileIcon: IconProp = faFile;

  if (!fileName) return { fileClass, fileIcon };

  const extension = fileName.split(".").pop();

  switch (extension?.toLowerCase()) {
    case "doc":
    case "docx":
      fileClass = "word-file";
      fileIcon = faFileWord;
      break;
    case "xls":
    case "xlsx":
    case "xlsm":
      fileClass = "excel-file";
      fileIcon = faFileExcel;
      break;
    case "pdf":
      fileClass = "pdf-file";
      fileIcon = faFilePdf;
      break;
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "bmp":
    case "svg":
    case "tiff":
    case "raw":
      fileClass = "image-file";
      fileIcon = faFileImage;
      break;
    case "mpeg":
    case "mp4":
    case "mov":
    case "wmv":
    case "avchd":
    case "avi":
    case "flv":
    case "f4v":
    case "swf":
    case "mkv":
    case "webm":
      fileClass = "video-file";
      fileIcon = faFileVideo;
      break;
    case "mp3":
    case "aac":
    case "ogg":
    case "flac":
    case "alac":
    case "wav":
    case "aiff":
    case "dsd":
    case "pcm":
      fileClass = "audio-file";
      fileIcon = faFileAudio;
      break;
    case "txt":
    case "rtf":
      fileClass = "text-file";
      fileIcon = faFileText;
      break;
    case "zip":
    case "rar":
    case "tar":
      fileClass = "archive-file";
      fileIcon = faFileArchive;
      break;
  }

  return { fileClass, fileIcon };
}

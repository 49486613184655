import { collection, getDocs, doc, getDoc, setDoc, addDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../_config/firebaseConfig"; // Import your Firebase Firestore instance here
import { GetSingleArgs, PageAndSearchArgs, PagedData, Product } from "../../interfaces";
import { StateTopicEnum } from "../../enums";
import { publish } from "../../hooks";
import { ref, uploadBytes, getDownloadURL, deleteObject, getMetadata } from "firebase/storage";
import { storage } from "../../_config/firebaseConfig"; // Import Firebase Storage

interface ProductService {
  getProductByPage: (args: PageAndSearchArgs) => Promise<PagedData<Product>>;
  getProductSingle: (args: GetSingleArgs) => Promise<any>;
  createProduct: (data: Product) => Promise<any>;
  updateProduct: (id: string, data: Product) => Promise<any>;
  deleteProduct: (id?: string) => Promise<void>;
}
const productRef = collection(db, "Products");
const service: ProductService = {
  getProductByPage: async ({ search, pageNo }: PageAndSearchArgs) => {
    const pageSize = 13;
    try {
      const querySnapshot = await getDocs(productRef);

      const products: Product[] = [];

      querySnapshot.forEach((doc) => {
        const productData = doc.data();
        productData.id = doc.id;

        products.push({
          id: productData.id,
          title: productData.title,
        });
      });
      return {
        pageNo: pageNo,
        pageSize: pageSize,
        pageCount: 1,
        itemCount: products.length,
        data: products,
      };

    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  getProductSingle: async ({ id }: GetSingleArgs) => {
    try {
      const productRef = doc(db, "Products", id);
      const docSnapshot = await getDoc(productRef);

      if (docSnapshot.exists()) {
        const productData = docSnapshot.data();
        productData.id = docSnapshot.id;
        return productData as Product;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  createProduct: async (data: Product) => {
    try {
      
      const productRef = collection(db, "Products");
      const docRef = await addDoc(productRef, data);
      publish(StateTopicEnum.ReloadUpdate, null);
      return { success: true, id: docRef.id };
    } catch (error) {
      console.error("Firebase error:", error);
      return { success: false, error: error };
    }
  },

  updateProduct: async (id: string, data: Product) => {
  try {
    const productRef = doc(db, "Products", id);

    // Get the current product data from Firestore
    const productSnapshot = await getDoc(productRef);
    const currentProductData = productSnapshot.data();
   

    // Update the product data in Firestore
    await setDoc(productRef, data);
    publish(StateTopicEnum.ReloadUpdate, null);
    return { success: true };
  } catch (error) {
    console.error("Firebase error:", error);
    return { success: false, error: error };
  }
},
  
  deleteProduct: async (id?: string): Promise<void> => {
  try {
    if (!id) {
      throw new Error("Product ID is missing.");
    }

    const productRef = doc(db, "Products", id);

    // Get the product data to retrieve the image URL
    const productSnapshot = await getDoc(productRef);
    const productData = productSnapshot.data();

    // Check if the product has an image URL
    if (productData && productData.image) {
      // Extract the image path from the URL (assuming it's a full storage path)
      const imagePath = productData.image;

      // Get a reference to the image in Firebase Storage
      const imageRef = ref(storage, imagePath);

      // Delete the product document from Firestore
      await deleteDoc(productRef);

      // Delete the associated image from Firebase Storage
      await deleteObject(imageRef);

      console.log("Product and associated image deleted successfully.");
    } else {
      // If there's no image associated with the product, simply delete the product document
      await deleteDoc(productRef);

      console.log("Product deleted successfully.");
    }
  } catch (error) {
    console.error("Firebase error:", error);
  }
},
};

export default service;

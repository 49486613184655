import PropTypes from "prop-types";

import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { BsCurrencyDollar } from "react-icons/bs";
import { IconType } from "react-icons";

export const OverviewBudget = (props: any) => {
  const { sx, value } = props;
  const IconComponent = BsCurrencyDollar as IconType;
  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              Active Bookings
            </Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: "primary.main",
              height: 56,
              width: 56,
            }}
          >
            <SvgIcon
              component={IconComponent}
              sx={{
                fontSize: "56px",
                width: "40px",
                height: "66px",
                marginLeft: "12px",
                marginTop: "12px",
              }}
            />
          </Avatar>
        </Stack>
        <Typography variant="h5">{value}</Typography>
      </CardContent>
    </Card>
  );
};

OverviewBudget.prototypes = {
  difference: PropTypes.number,
  positive: PropTypes.bool,
  sx: PropTypes.object,
  value: PropTypes.string.isRequired,
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import Form from "../../../components/form/Form";
import { InstructionWrapper, TextField } from "../../../components/fields";
import { publish, useStateReducer } from "../../../hooks";
import { getQueryParameter, validateObject } from "../../../utilities";
import { Category, NavigableComponent } from "../../../interfaces";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { FaSave, FaTimes } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routes } from "../../../_config";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import service from "../../../services/Category/categoryService";
interface EditCategoryDataProps {
  category: Category;
}
interface State {
  id?: string;
  category?: string;
}
export default function EditCategoryItem({
  setToolbarConfig,
  category,
}: EditCategoryDataProps & NavigableComponent) {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const location = useLocation();
  const mode = getQueryParameter(location.search, "mode");
  const [state, setState] = useStateReducer<State>({
    ...category,
  });

  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const handleChange = (prop: string, value: any) => {
    setState({ [prop]: value });
  };

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [];

    toolbarItems = [
      {
        label: "Cancel",
        icon: FaTimes,
        onClick: handleCancel,
      },
      "|",
      {
        label: "Save",
        icon: FaSave,
        onClick: handleSave,
        primary: true,
        raised: true,
      },
    ];

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.category.go(),
    });
  }, [mode, category]);

  const handleCancel = () => {
    if (categoryId === "add")
      navigate(`${routes.category.go()}`, { replace: false });
    else
      navigate(`${routes.category.go()}/${categoryId}`, {
        replace: false,
      });
  };

  const handleSave = async () => {
    var result: any;
    var entry = {
      category: stateRef.current.category,
    } as Category;

    if (categoryId === "add") result = await service.createCategory(entry);
    else result = await service.updateCategory(categoryId ?? "", entry);
    if (result?.success === true)
      showAlert({
        content: `Saving category ${state?.category} now...`,
        options: {
          type: AlertTypeEnum.Info,
          actions: [
            {
              text: "Ok",
              primary: true,
              onClick: () => {
                hideAlert();
                navigate(`${routes.categoryItem.go(categoryId)}`);
                publish(StateTopicEnum.CurrentItem, null);
              },
            },
          ],
        },
      });
    else
      showAlert({
        content: `Error saving category ${stateRef.current?.category}`,
        options: {
          type: AlertTypeEnum.Error,
          actions: [
            {
              text: "OK",
              primary: true,
              onClick: () => {
                hideAlert();
              },
            },
          ],
        },
      });
  };

  const hasError = (prop: string) => (errors[prop] ? true : false);

  const errors = validateObject(
    {
      name: { regex: /.+/, message: "Category ID number is required." },
    },
    state
  );

  return (
    <>
      <Form title="Category details">
        <InstructionWrapper error={errors["category"]}>
          <TextField
            label="Category Name"
            onChange={(e) => handleChange("category", e.target.value)}
            value={state?.category}
            error={hasError("category")}
          />
        </InstructionWrapper>
      </Form>
    </>
  );
}

import { collection, getDocs, doc, getDoc, setDoc, addDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../_config/firebaseConfig"; // Import your Firebase Firestore instance here
import { GetSingleArgs, PageAndSearchArgs, PagedData, Category } from "../../interfaces";
import { StateTopicEnum } from "../../enums";
import { publish } from "../../hooks";

interface CategoryService {
  getCategoryByPage: (args: PageAndSearchArgs) => Promise<PagedData<Category>>;
  getCategories: () => Promise<any>;
  getCategorySingle: (args: GetSingleArgs) => Promise<any>;
  createCategory: (data: Category) => Promise<any>;
  updateCategory: (id: string, data: Category) => Promise<any>;
  deleteCategory: (id?: string) => Promise<void>;
}
const categoryRef = collection(db, "ProductCategories");
const service: CategoryService = {
  getCategoryByPage: async ({ search, pageNo }: PageAndSearchArgs) => {
    const pageSize = 13;
    try {
      const querySnapshot = await getDocs(categoryRef);

      const categories: Category[] = [];

      querySnapshot.forEach((doc) => {
        const categoryData = doc.data();
        categoryData.id = doc.id;

        categories.push({
          id: categoryData.id,
          category: categoryData.category,
        });
      });
      return {
        pageNo: pageNo,
        pageSize: pageSize,
        pageCount: 1,
        itemCount: categories.length,
        data: categories,
      };

    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  getCategories: async () => {
    try {
      const querySnapshot = await getDocs(categoryRef);

      const categories: Category[] = [];

      querySnapshot.forEach((doc) => {
        const categoryData = doc.data();
        categoryData.id = doc.id;

        categories.push({
          id: categoryData.id,
          category: categoryData.category,
        });
      });
      return categories;

    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  getCategorySingle: async ({ id }: GetSingleArgs) => {
    try {
      const categoryRef = doc(db, "ProductCategories", id);
      const docSnapshot = await getDoc(categoryRef);

      if (docSnapshot.exists()) {
        const categoryData = docSnapshot.data();
        categoryData.id = docSnapshot.id;
        return categoryData as Category;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  createCategory: async (data: Category) => {
    try {
      
      const docRef = await addDoc(categoryRef, data);
      publish(StateTopicEnum.ReloadUpdate, null);
      return { success: true, id: docRef.id };
    } catch (error) {
      console.error("Firebase error:", error);
      return { success: false, error: error };
    }
  },

  updateCategory: async (id: string, data: Category) => {
    try {
        const categoryRef = doc(db, "ProductCategories", id);
      await setDoc(categoryRef, data);
      publish(StateTopicEnum.ReloadUpdate, null);
      return { success: true };
    } catch (error) {
      console.error("Firebase error:", error);
      return { success: false, error: error };
    }
  },
  deleteCategory: async (id?: string): Promise<void> => {
    try {
      if (!id) {
        throw new Error("Category ID is missing.");
      }

      const categoryRef = doc(db, "ProductCategories", id);

      await deleteDoc(categoryRef);
    } catch (error) {
      console.error("Firebase error:", error);
    }
  },
};

export default service;

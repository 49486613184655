/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Form from "../../../components/form/Form";
import { TextField } from "../../../components/fields";
import { NavigableComponent } from "../../../interfaces";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { FaArrowLeft, FaEdit, FaTrash } from "react-icons/fa";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { routes } from "../../../_config";
import { useNavigate, useParams } from "react-router-dom";
import Product from "../../../interfaces/Product";
import service from "../../../services/Products/productService";
import { publish } from "../../../hooks";
interface ViewProductDataProps {
  product: Product;
}

export default function ViewProductItem({
  setToolbarConfig,
  product,
}: ViewProductDataProps & NavigableComponent) {
  const { productId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [];

    toolbarItems = [
      {
        label: "Back",
        icon: FaArrowLeft,
        primary: true,
        raised: true,
        onClick: () => {
          navigate(routes.product.go(), {
            replace: false,
          });
        },
      },
      "|",
      {
        label: "Edit",
        icon: FaEdit,
        primary: true,
        raised: true,
        onClick: handleEdit,
      },
      "|",
      { label: "Delete", icon: FaTrash, onClick: handleDelete },
    ];

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.product.go(productId),
    });
  }, []);

  const handleEdit = () => {
    navigate(routes.productItem.go(productId) + "?mode=edit", {
      replace: false,
    });
  };

  const handleDelete = () => {
    showAlert({
      content: `Deleting the product ${product?.title} now! Are you sure?`,
      options: {
        type: AlertTypeEnum.Error,
        actions: [
          {
            text: "Yes",
            primary: true,
            onClick: () => {
              hideAlert();
              service.deleteProduct(productId ?? "");
              navigate(`${routes.product.go()}`);
              publish(StateTopicEnum.CurrentItem, null);
              publish(StateTopicEnum.ReloadUpdate, null);
            },
          },
          { text: "No" },
        ],
      },
    });
  };

  return (
    <>
      <Form title="Product primary details">
        <TextField label="Product Title" value={product.title} readOnly />
      </Form>
    </>
  );
}

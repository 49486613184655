import { Chip } from "@mui/material";
import { TextField } from "../../../components/fields";
import "./_styles.scss";

interface BookingDetailModalProps {
  booking?: any;
}

export default function BookingDetailModal({
  booking,
}: BookingDetailModalProps) {
  const colorCheck = (status: any) => {
    let className = "";
    if (status === "confirmed") {
      className += " confirmed-event";
    } else if (status === "pending") {
      className += " pending-event";
    } else if (status === "cancelled") {
      className += " cancelled-event";
    }

    return className;
  };
  return (
    <div style={{ margin: 30 }}>
      <Chip label={booking.status} className={colorCheck(booking.status)} />
      <TextField
        label="Client Name:"
        className="list-item"
        value={booking?.name ?? ""}
        readOnly
      />
      <TextField
        label="Client Email:"
        className="list-item"
        value={booking?.email ?? ""}
        readOnly
      />
      <TextField
        label="Client Contact Number:"
        className="list-item"
        value={booking?.contactNumber ?? ""}
        readOnly
      />
      <TextField
        label="Booking Date"
        className="list-item"
        value={booking?.date}
        readOnly
      />
      <TextField
        label="Number of Adults:"
        className="list-item"
        value={booking?.noOfAdults ?? ""}
        readOnly
      />
      <TextField
        label="Number of Children:"
        className="list-item"
        value={booking?.noOfChildren ?? ""}
        readOnly
      />
      <TextField
        label="Message or Special Request:"
        className="list-item"
        value={booking?.message ?? ""}
        readOnly
      />
    </div>
  );
}

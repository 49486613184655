/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination } from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { FaHourglass } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { Scrollable } from "../../components";
import { publish, useStateReducer } from "../../hooks";
import { NavigableComponent } from "../../interfaces";
import { getQueryParameter, classNameBuilder } from "../../utilities";
import ReviewDataGridTable from "./components/ReviewDataGrid";
import "./_styles.scss";
import service from "../../services/Reviews/reviewService";
import Review from "../../interfaces/Reviews/Review";
import { StateTopicEnum } from "../../enums";

interface ReviewDashboardPageProps {
  index?: boolean;
}
interface State {
  review?: Review[];
  isSaveDisabled?: boolean;
  page?: number | string;
  pageCount?: number;
  search?: string;
  searchEntry?: string;
  filters?: string[];
}

export default function ReviewDashboardPage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
}: NavigableComponent & ReviewDashboardPageProps) {
  useEffect(() => {
    setBreadcrumbs([{ text: "Review Dashboard" }]);
    setToolbarConfig({
      allowSearch: false,
      toolbarItems: [],
    });
  }, []);
  const location = useLocation();
  const navigate = useNavigate();

  const pageQuery = getQueryParameter<number>(
    location.search,
    "page",
    parseInt
  );
  const searchQuery = getQueryParameter<number>(location.search, "search");

  const [state, setState] = useStateReducer<State>({
    page: isNaN(parseInt(pageQuery.toString())) ? 1 : pageQuery,
    search: searchQuery.toString(),
    searchEntry: searchQuery.toString(),
    filters: [],
  });

  const { page, searchEntry, search } = state;

  const loadData = async () => {
    setState({ ...state, pageCount: undefined, review: undefined });
    let filters: Map<string, string> = new Map<string, string>();
    state.filters?.map((e, i) => {
      filters.set(`status${i}`, e);
    });
    const result = await service.getReviewByPage({
      pageNo,
      search: searchQuery.toString(),
    });
    setState({
      ...state,
      pageCount: result.pageCount,
      review: result.data,
    });
  };

  let pageNo = parseInt(page?.toString() ?? "");

  if (isNaN(pageNo)) pageNo = 1;

  useEffect(() => {
    loadData();
  }, [state.page, searchEntry]);

  let classes = classNameBuilder("list-library-page");

  const { pageCount } = state;

  const handlePage = (_: any, p: number) => {
    let query = `?page=${p} ?? ""
    }&search=${search}`;
    setState({ page: p });
    navigate(`${query}`, { replace: false });
  };
  const handleSave = async (r: Review) => {
    await service.updateReview(r.id ?? "", r);
    publish(StateTopicEnum.CurrentItem, false);
  };

  let content = (
    <Scrollable>
      <div key={`company-library-page`}>
        <div className="page-content">
          <ReviewDataGridTable rows={state.review} onSave={handleSave} />
        </div>
      </div>
    </Scrollable>
  );

  if (!state.review)
    return (
      <div className="list-library-page">
        <div className="loading">
          <FaHourglass size={40} />
        </div>
      </div>
    );
  return (
    <>
      <Helmet>
        <title>Review Dashboard</title>
      </Helmet>

      <div className={classes} key={`reviewmain-dashboard`}>
        <div className="review-main-header">
          <h2 style={{ color: "#000", marginLeft: "24px" }}>Reviews</h2>
        </div>
        {content}
        {(pageCount ?? 0) > 1 ? (
          <Pagination
            className="pager"
            shape="rounded"
            size="small"
            page={pageNo}
            count={pageCount}
            color="primary"
            siblingCount={0}
            boundaryCount={1}
            showFirstButton
            showLastButton
            onChange={handlePage}
          />
        ) : null}
      </div>
    </>
  );
}

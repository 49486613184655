/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStateReducer, useSubscription } from "../../hooks";
import { NavigableComponent } from "../../interfaces";
import { FaHandPointer, FaHourglass } from "react-icons/fa";
import { classNameBuilder, getQueryParameter } from "../../utilities";
import { routes } from "../../_config";
import { Scrollable } from "../../components";

import EditOfferItem from "./components/EditOfferItem";
import ViewOfferItem from "./components/ViewOfferItem";
import { StateTopicEnum } from "../../enums";
import Offers from "../../interfaces/Offers/Offers";
import service from "../../services/Offers/offerService";

interface OfferItemPageProps {
  index?: boolean;
}
interface State {
  offer?: Offers | null;
  offerId?: string;
  isSaveDisabled?: boolean;
}

export default function OfferItemPage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
}: NavigableComponent & OfferItemPageProps) {
  const { offerId } = useParams();
  const [state, setState] = useStateReducer<State>({ isSaveDisabled: true });
  const { offer } = state;
  const loading = offer ? false : true;
  const location = useLocation();
  const navigate = useNavigate();
  const mode = getQueryParameter(location.search, "mode");

  const load = async () => {
    setState({ ...state, offer: null });
    let offer: Offers = {
      id: "",
      offerName: "",
    };
    if (offerId !== "add" && !!offerId) {
      const result = await service.getOfferSingle({
        id: offerId ?? "",
      });

      if (result.success === false) navigate(routes.package.go());
      else {
        offer = result as Offers;
      }
    }
    setState({ ...state, offer: offer });
  };

  useEffect(() => {
    setBreadcrumbs(
      index
        ? [{ text: "Offers" }]
        : [
            { text: "Offers", to: routes.package.raw },
            { text: state.offer?.offerName ?? "" },
          ]
    );
    setState({ offer: null, isSaveDisabled: true });
    if (offerId) load();
  }, [offerId, mode]);

  useSubscription(StateTopicEnum.CurrentItem, () => {
    load();
  });

  const classes = classNameBuilder(
    "h-app-item-page-content",
    index ? "index" : "",
    loading ? "loading" : ""
  );

  let content = <></>;

  if (index)
    content = (
      <>
        <FaHandPointer /> Select a offer on the left.
      </>
    );
  else if (!state.offer)
    content = (
      <>
        <FaHourglass /> Loading...
      </>
    );
  else
    content = (
      <Scrollable className="items">
        <h2>{state.offer.offerName}</h2>

        {mode === "edit" ? (
          <EditOfferItem
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            offer={state.offer}
          />
        ) : (
          <ViewOfferItem
            offer={state.offer}
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
          />
        )}
      </Scrollable>
    );

  return (
    <div className={classes} key={`offer-page-${offerId}`}>
      {content}
    </div>
  );
}

import { createContext, useContext } from "react";
import { UserContext } from "../interfaces";

let AuthContext = createContext<UserContext | null>(null);

function useAuthentication() {
  return useContext<UserContext | null>(AuthContext);
}

export { AuthContext, useAuthentication };

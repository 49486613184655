import { GetSingleArgs, Module } from "../interfaces";
import { asyncify } from "../utilities";
import { navigationModules } from "./data";

interface ModuleService {
  getUserModules(): Promise<Module[]>;
  getAllModules(): Promise<Module[]>;
  getSingleModule(args: GetSingleArgs): Promise<Module>;
}

let _CACHE: Module[] = [];

const service: ModuleService = {
  async getUserModules() {
    return await asyncify(() => navigationModules, 100 + Math.random() * 150);
  },

  async getAllModules() {
    if (_CACHE.length) return _CACHE;

    return await asyncify(() => {
      _CACHE = navigationModules;

      return _CACHE;
    }, 100 + Math.random() * 150);
  },

  async getSingleModule({ id }) {
    const found = _CACHE.filter((m) => m.id === id)[0];

    if (found) return found;

    return (await service.getAllModules()).filter((m) => m.id === id)[0];
  },
};

export default service;

import React, { useEffect, lazy, Suspense } from "react";
import { FaHandPointer } from "react-icons/fa";
import { useLocation, useParams } from "react-router-dom";
import { Scrollable } from "../../components";
import { StateTopicEnum } from "../../enums";
import { getCurrentData } from "../../hooks";
import { NavigableComponent } from "../../interfaces";
import { classNameBuilder, getQueryParameter } from "../../utilities";

interface SettingItemPageProps {
  index?: boolean;
}

export default function SettingItemPage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
}: NavigableComponent & SettingItemPageProps) {
  const { settingId } = useParams();
  const loading = false;
  let ComponentToRender: any = "";
  let componentName: any = "";
  let componentPath: any = "";
  const location = useLocation();
  const mode = getQueryParameter(location.search, "mode");

  const currentModule: string = getCurrentData(StateTopicEnum.ActiveModule);
  const item = currentModule;

  componentName = currentModule;
  componentPath = currentModule;
  ComponentToRender = currentModule;

  const classes = classNameBuilder(
    "h-app-item-page-content",
    index ? "index" : "",
    loading ? "loading" : ""
  );

  let content = <></>;

  if (mode == "edit" || mode == "add") {
    componentName = "Edit" + componentName;
    componentPath = componentPath.toLowerCase() + "s-page";
    ComponentToRender = lazy(
      () => import(`../${componentPath}/components/${componentName}`)
    );
  } else {
    componentName = "View" + componentName;
    componentPath = componentPath.toLowerCase() + "s-page";
    ComponentToRender = lazy(
      () => import(`../${componentPath}/components/${componentName}`)
    );
  }

  const _props = {
    setToolbarConfig: setToolbarConfig,
    setBreadcrumbs: setBreadcrumbs,
    [item.toLowerCase()]: "",
  };

  if (index)
    content = (
      <>
        <FaHandPointer /> Select an item on the left.
      </>
    );
  else
    content = (
      <Scrollable>
        <Suspense>
          <ComponentToRender {..._props} />
        </Suspense>
      </Scrollable>
    );

  return (
    <div className={classes} key={`setting-page-${settingId}`}>
      {content}
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Library, PageWrapper } from "../../components";
import { NavigableComponent } from "../../interfaces";
import { routes } from "../../_config";
import LibraryCard from "./OfferLibraryCard";
import "./_styles.scss";
import { ToolItem } from "../../components/toolbar/Toolbar";
import { FaPlus } from "react-icons/fa";
import service from "../../services/Offers/offerService";

export default function OfferLibraryPage({
  setToolbarConfig,
}: NavigableComponent) {
  const navigate = useNavigate();
  const { offerId } = useParams();

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [];
    toolbarItems.push({
      label: "Add",
      icon: FaPlus,
      primary: true,
      raised: true,
      onClick: async () => {
        navigate(`${routes.offerItem.go("add")}?mode=edit`);
      },
    });
    setToolbarConfig({
      allowSearch: true,
      searchPath: routes.offer.go(),
      toolbarItems: toolbarItems,
    });
  }, [offerId]);

  const meta = { title: "Package" };

  return (
    <PageWrapper meta={meta} className="h-offer-library-page" secure>
      <div className="h-offer-library-page-content" key={`offer-library-page`}>
        <PageWrapper noStyle meta={meta}>
          <Library
            config={{
              cardTemplate: LibraryCard,
              extractKey: (a) => a.id,
              extractPath: (a) => a.id,
              searchPath: routes.offer.go(),
              service: service.getOfferByPage,
            }}
          />
          <Outlet />
        </PageWrapper>
      </div>
    </PageWrapper>
  );
}

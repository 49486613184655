import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  addDoc,
  deleteDoc,
  orderBy,
  query,
} from "firebase/firestore";
import { db, storage } from "../../_config/firebaseConfig"; // Import your Firebase Firestore instance here
import {
  GetSingleArgs,
  PageAndSearchArgs,
  PagedData,
  Product,
} from "../../interfaces";
import { StateTopicEnum } from "../../enums";
import { publish } from "../../hooks";
import Package from "../../interfaces/Package";
import {
  deleteObject,
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytes,
} from "firebase/storage";

interface PackageService {
  getPackageByPage: (args: PageAndSearchArgs) => Promise<PagedData<Package>>;
  getPackages: () => Promise<any>;
  getPackageSingle: (args: GetSingleArgs) => Promise<any>;
  createPackage: (
    data: Package,
    imageFile: File | null,
    file: File | null
  ) => Promise<any>;
  updatePackage: (
    id: string,
    data: Package,
    image: File | null,
    file: File | null
  ) => Promise<any>;
  deletePackage: (id?: string) => Promise<void>;
  getProducts: () => Promise<any>;
}
const packageRef = collection(db, "Packages");
const service: PackageService = {
  getPackageByPage: async ({ search, pageNo }: PageAndSearchArgs) => {
    const pageSize = 13;
    try {
      const q = query(packageRef, orderBy("title"));
      const querySnapshot = await getDocs(q);

      const packages: Package[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data();
        packageData.id = doc.id;

        packages.push({
          id: packageData.id,
          title: packageData.title,
          subTitle: packageData.subTitle,
          duration: packageData.duration,
          pickup: packageData.pickup,
          dropOff: packageData.dropOff,
          pack: packageData.pack,
          included: packageData.included,
          startLocation: packageData.startLocation,
          conditions: packageData.conditions,
          price: packageData.price,
          discount: packageData.discount,
          description: packageData.description,
          image_url: packageData.image_url,
          iternerary: packageData.iternerary,
          tag: packageData.tag,
        });
      });
      return {
        pageNo: pageNo,
        pageSize: pageSize,
        pageCount: 1,
        itemCount: packages.length,
        data: packages,
      };
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  getPackages: async () => {
    try {
      const querySnapshot = await getDocs(packageRef);

      const packages: Package[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data();
        packageData.id = doc.id;

        packages.push({
          id: packageData.id,
          title: packageData.title,
          subTitle: packageData.subTitle,
          duration: packageData.duration,
          pickup: packageData.pickup,
          dropOff: packageData.dropOff,
          pack: packageData.pack,
          included: packageData.included,
          startLocation: packageData.startLocation,
          conditions: packageData.conditions,
          price: packageData.price,
          discount: packageData.discount,
          description: packageData.description,
          image_url: packageData.image_url,
          iternerary: packageData.iternerary,
          tag: packageData.tag,
        });
      });
      return packages;
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  getPackageSingle: async ({ id }: GetSingleArgs) => {
    try {
      const packageRef = doc(db, "Packages", id);
      const docSnapshot = await getDoc(packageRef);

      if (docSnapshot.exists()) {
        const packageData = docSnapshot.data();
        packageData.id = docSnapshot.id;
        return packageData as Package;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  createPackage: async (
    data: Package,
    imageFile: File | null,
    file: File | null
  ) => {
    try {
      if (imageFile) {
        const storageRef = ref(storage, `packageImages/${imageFile.name}`);

        // Upload the image to Firebase Storage
        await uploadBytes(storageRef, imageFile);
        const imageUrl = await getDownloadURL(storageRef);

        // Add the image URL to the product data
        data.image_url = imageUrl;
      }
      if (file) {
        const storageRef = ref(storage, `iterneraryFiles/${file.name}`);

        // Upload the image to Firebase Storage
        await uploadBytes(storageRef, file);
        const fileUrl = await getDownloadURL(storageRef);

        // Add the image URL to the product data
        data.iternerary = fileUrl;
      }
      const docRef = await addDoc(packageRef, data);
      publish(StateTopicEnum.ReloadUpdate, null);
      return { success: true, id: docRef.id };
    } catch (error) {
      console.error("Firebase error:", error);
      return { success: false, error: error };
    }
  },

  updatePackage: async (
    id: string,
    data: Package,
    image: File | null,
    file: File | null
  ) => {
    try {
      const packageRef = doc(db, "Packages", id);
      const packageSnapshot = await getDoc(packageRef);
      const packageData = packageSnapshot.data();
      if (image) {
        // If a new image is provided, delete the previous image from Firebase Storage
        const previousImageUrl = packageData?.image;
        if (previousImageUrl) {
          try {
            // Construct a reference to the previous image
            const previousImageRef = ref(storage, previousImageUrl);

            // Check if the previous image exists before attempting to delete it
            const imageExists = await getMetadata(previousImageRef)
              .then(() => true)
              .catch(() => false);

            if (imageExists) {
              // Delete the previous image
              await deleteObject(previousImageRef);
              console.log("Previous image deleted successfully.");
            } else {
              console.log("Previous image does not exist. No deletion needed.");
            }
          } catch (deleteError) {
            console.error("Error deleting previous image:", deleteError);
          }
        }

        // Upload the new image to Firebase Storage with a new path
        const storageRef = ref(storage, `packageImages/${image.name}`);
        await uploadBytes(storageRef, image);
        const imageUrl = await getDownloadURL(storageRef);

        // Update the product data with the new image URL
        data.image_url = imageUrl;
      }
      if (file) {
        // If a new image is provided, delete the previous image from Firebase Storage
        const previousFileUrl = packageData?.iternerary;
        if (previousFileUrl) {
          try {
            // Construct a reference to the previous image
            const previousFileRef = ref(storage, previousFileUrl);

            // Check if the previous image exists before attempting to delete it
            const fileExists = await getMetadata(previousFileRef)
              .then(() => true)
              .catch(() => false);

            if (fileExists) {
              // Delete the previous image
              await deleteObject(previousFileRef);
              console.log("Previous file deleted successfully.");
            } else {
              console.log("Previous file does not exist. No deletion needed.");
            }
          } catch (deleteError) {
            console.error("Error deleting previous image:", deleteError);
          }
        }

        // Upload the new image to Firebase Storage with a new path
        const storageRef = ref(storage, `iterneraryFiles/${file.name}`);
        await uploadBytes(storageRef, file);
        const imageUrl = await getDownloadURL(storageRef);

        // Update the product data with the new image URL
        data.image_url = imageUrl;
      }
      await setDoc(packageRef, data);
      publish(StateTopicEnum.ReloadUpdate, null);
      return { success: true };
    } catch (error) {
      console.error("Firebase error:", error);
      return { success: false, error: error };
    }
  },
  deletePackage: async (id?: string): Promise<void> => {
    try {
      if (!id) {
        throw new Error("Package ID is missing.");
      }

      const packageRef = doc(db, "Packages", id);

      await deleteDoc(packageRef);
    } catch (error) {
      console.error("Firebase error:", error);
    }
  },
  getProducts: async () => {
    try {
      const packageRef = collection(db, "Products");
      const querySnapshot = await getDocs(packageRef);

      const products: Product[] = [];

      querySnapshot.forEach((doc) => {
        const productData = doc.data();
        productData.id = doc.id;

        products.push({
          id: productData.id,
          title: productData.title,
        });
      });
      return products;
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
};

export default service;

import React, { ReactNode, useEffect } from "react";
import { routes } from "../../_config";
import { AppLogo, Scrollable, SideNavLink, UserAccount } from "../";
import "./_styles.scss";
import { SettingConfig, Module } from "../../interfaces";
import { moduleService, settingService } from "../../services";
import { useStateReducer, useSubscription } from "../../hooks";
import { StateTopicEnum } from "../../enums";
import SideNavGroup from "./SideNavGroup";
import { FaArrowLeft } from "react-icons/fa";

interface State {
  modules?: Module[] | null;
  settings?: SettingConfig[] | null;
  loading?: boolean;
  context?: NavigationContext;
}

export enum NavigationContext {
  Modules,
  Settings,
}

const Wrapper = ({ children }: { children: ReactNode }) => (
  <nav className="h-app-side-nav" key="side-nav-wrapper">
    <AppLogo />
    <UserAccount allowSettings />
    <Scrollable>{children}</Scrollable>
  </nav>
);

export default function SideNav() {
  const [state, setState] = useStateReducer<State>({
    modules: null,
    settings: null,
    loading: true,
    context: NavigationContext.Modules,
  });
  const { modules, settings, loading, context } = state;

  useEffect(() => {
    const load = async () => {
      const modules = await moduleService.getAllModules();
      const settings = await settingService.getAllSettings();

      setState({ modules, settings, loading: false });
    };

    load();
  }, []);

  useSubscription<NavigationContext>(
    StateTopicEnum.NavigationContext,
    (ctx) => {
      setState({ context: ctx });
    }
  );

  const renderModule = (
    { id, name, route, children, component, icon }: Module,
    isChild?: boolean
  ) => {
    if (children?.length)
      return (
        <SideNavGroup label={name} icon={icon}>
          {children.map((module, i) => (
            <React.Fragment key={`child-${i}`}>
              {renderModule(module, true)}
            </React.Fragment>
          ))}
        </SideNavGroup>
      );

    return (
      <SideNavLink
        to={route.go(id)}
        icon={icon}
        className={isChild ? "sub-link" : ""}
        isNavLink
      >
        {name}
      </SideNavLink>
    );
  };

  if (loading) return <Wrapper>Loading...</Wrapper>;

  if (context === NavigationContext.Modules)
    return (
      <Wrapper>
        {modules?.map((module, i) => (
          <React.Fragment key={`module-${i}`}>
            {renderModule(module)}
          </React.Fragment>
        ))}
      </Wrapper>
    );

  if (context === NavigationContext.Settings)
    return (
      <Wrapper>
        <SideNavLink
          key="back"
          to="/"
          icon={FaArrowLeft}
          className="back"
          isNavLink={false}
        >
          Back
        </SideNavLink>
        {settings?.map((setting, i) => (
          <SideNavLink
            key={`setting-link-${i}`}
            to={routes.settingCategory.go(setting.id)}
            icon={setting.icon}
            isNavLink
          >
            {setting.name}
            <span className="description">{setting.description}</span>
          </SideNavLink>
        ))}
      </Wrapper>
    );

  return <Wrapper>Nothing to display</Wrapper>;
}

export class InterpolatedRoute extends String {
  go(...parts: (string | null | undefined)[]): string {
    const toTest = this.toString();
    const regex = /:[a-zA-Z0-1]+/g;
    let toReturn = toTest;
    let match;
    let counter = 0;

    while ((match = regex.exec(toTest)))
      toReturn = toReturn.replace(match[0], parts[counter++] ?? "#ERROR");

    return toReturn;
  }

  get raw() {
    return this.toString();
  }
}

const routes = {
  home: new InterpolatedRoute("/"),
  login: new InterpolatedRoute("/login"),
  register: new InterpolatedRoute("/register"),
  forgotPassword: new InterpolatedRoute("/forgotPassword"),
  profile: new InterpolatedRoute("/profile"),
  settings: new InterpolatedRoute("/settings"),
  settingCategory: new InterpolatedRoute("/settings/:settingConfig"),
  settingItem: new InterpolatedRoute("/settings/:settingConfig/:settingId"),
  field: new InterpolatedRoute("/settings/fields-library/:fieldId"),
  product: new InterpolatedRoute("/products"),
  productItem: new InterpolatedRoute("/products/:productId"),
  order: new InterpolatedRoute("/order"),
  orderItem: new InterpolatedRoute("/order/:orderId"),
  category: new InterpolatedRoute("/category"),
  categoryItem: new InterpolatedRoute("/category/:categoryId"),
  service: new InterpolatedRoute("/service"),
  serviceItem: new InterpolatedRoute("/service/:serviceId"),
  booking: new InterpolatedRoute("/booking"),
  package: new InterpolatedRoute("/package"),
  packageItem: new InterpolatedRoute("/package/:packageId"),
  review: new InterpolatedRoute("/review"),
  reviewItem: new InterpolatedRoute("/review/:reviewId"),
  message: new InterpolatedRoute("/message"),
  messageItem: new InterpolatedRoute("/message/:messageId"),
  offer: new InterpolatedRoute("/offer"),
  offerItem: new InterpolatedRoute("/offer/:offerId"),
};

export default routes;

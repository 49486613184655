import React from "react";
import { Route, Routes } from "react-router-dom";
import { NavigableComponent } from "./interfaces";
import {
  Error404,
  HomePage,
  LoginPage,
  ProfilePage,
  SettingConfigPage,
  SettingsPage,
  SettingItemPage,
  ProductsItemPage,
  ProductsLibraryPage,
  PackageItemPage,
  PackageLibraryPage,
} from "./pages";

import { routes } from "./_config";
import CategoryLibraryPage from "./pages/category-page/CategoryLibraryPage";
import CategoryItemPage from "./pages/category-page/CategoryItemPage";
import ServiceItemPage from "./pages/services-page/ServiceItemPage";
import ServiceLibraryPage from "./pages/services-page/ServiceLibraryPage";
import BookingsPage from "./pages/bookings-page/BookingsPage";
import RegisterPage from "./pages/register-page/RegisterPage";
import ReviewDashboardPage from "./pages/reviews-page/ReviewDashboardPage";
import MessageDashboardPage from "./pages/messages-page/MessageDashboardPage";
import OfferLibraryPage from "./pages/offer-page/OfferLibraryPage";
import OfferItemPage from "./pages/offer-page/OfferItemPage";

export default function AppRoutes({
  setBreadcrumbs,
  setToolbarConfig,
}: NavigableComponent) {
  const routeComponentProps: NavigableComponent = {
    setBreadcrumbs,
    setToolbarConfig,
  };

  return (
    <Routes>
      <Route
        path={routes.home.raw}
        element={<HomePage {...routeComponentProps} />}
      />
      <Route path={routes.login.raw} element={<LoginPage />} />
      <Route path={routes.register.raw} element={<RegisterPage />} />
      <Route
        path={routes.profile.raw}
        element={<ProfilePage {...routeComponentProps} />}
      />
      <Route
        path={routes.settings.raw}
        element={<SettingsPage {...routeComponentProps} />}
      >
        <Route
          index
          element={<SettingConfigPage index {...routeComponentProps} />}
        />
        <Route
          path={routes.settingCategory.raw}
          element={<SettingConfigPage {...routeComponentProps} />}
        >
          <Route
            index
            element={<SettingItemPage index {...routeComponentProps} />}
          />
          <Route
            path={routes.settingItem.raw}
            element={<SettingItemPage {...routeComponentProps} />}
          />
        </Route>
      </Route>
      <Route
        path={routes.product.raw}
        element={<ProductsLibraryPage {...routeComponentProps} />}
      >
        <Route
          index
          element={<ProductsItemPage index {...routeComponentProps} />}
        />
        <Route
          path={routes.productItem.raw}
          element={<ProductsItemPage {...routeComponentProps} />}
        />
      </Route>
      <Route
        path={routes.category.raw}
        element={<CategoryLibraryPage {...routeComponentProps} />}
      >
        <Route
          index
          element={<CategoryItemPage index {...routeComponentProps} />}
        />
        <Route
          path={routes.categoryItem.raw}
          element={<CategoryItemPage {...routeComponentProps} />}
        />
      </Route>
      <Route
        path={routes.service.raw}
        element={<ServiceLibraryPage {...routeComponentProps} />}
      >
        <Route
          index
          element={<ServiceItemPage index {...routeComponentProps} />}
        />
        <Route
          path={routes.serviceItem.raw}
          element={<ServiceItemPage {...routeComponentProps} />}
        />
      </Route>
      <Route
        path={routes.package.raw}
        element={<PackageLibraryPage {...routeComponentProps} />}
      >
        <Route
          index
          element={<PackageItemPage index {...routeComponentProps} />}
        />
        <Route
          path={routes.packageItem.raw}
          element={<PackageItemPage {...routeComponentProps} />}
        />
      </Route>
      <Route
        path={routes.booking.raw}
        element={<BookingsPage index {...routeComponentProps} />}
      />
      <Route
        path={routes.review.raw}
        element={<ReviewDashboardPage {...routeComponentProps} />}
      ></Route>
      <Route
        path={routes.message.raw}
        element={<MessageDashboardPage {...routeComponentProps} />}
      ></Route>
      <Route
        path={routes.offer.raw}
        element={<OfferLibraryPage {...routeComponentProps} />}
      >
        <Route
          index
          element={<OfferItemPage index {...routeComponentProps} />}
        />
        <Route
          path={routes.offerItem.raw}
          element={<OfferItemPage {...routeComponentProps} />}
        />
      </Route>
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}

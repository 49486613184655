import { GetSingleArgs, SettingConfig } from "../interfaces";
import { asyncify } from "../utilities";
import { settingsModules } from "./data";

interface SettingService {
  getUserSettings(): Promise<SettingConfig[]>;
  getAllSettings(): Promise<SettingConfig[]>;
  getSingleSetting(args: GetSingleArgs): Promise<SettingConfig>;
}

let _CACHE: SettingConfig[] = [];

const service: SettingService = {
  async getUserSettings() {
    return await service.getAllSettings();
  },

  async getAllSettings() {
    if (_CACHE.length) return _CACHE;

    return await asyncify(() => {
      _CACHE = settingsModules;

      return _CACHE;
    }, 50 + Math.random() * 200);
  },

  async getSingleSetting({ id }) {
    const found = _CACHE.filter((s) => s.id === id)[0];

    if (found) return found;

    return (await service.getAllSettings()).filter((s) => s.id === id)[0];
  },
};

export default service;

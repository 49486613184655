import { collection, getDocs, doc, getDoc, setDoc, addDoc, deleteDoc } from "firebase/firestore";
import { db, storage } from "../../_config/firebaseConfig"; // Import your Firebase Firestore instance here
import { GetSingleArgs, PageAndSearchArgs, PagedData, Service } from "../../interfaces";
import { StateTopicEnum } from "../../enums";
import { publish } from "../../hooks";
import { deleteObject, getDownloadURL, getMetadata, ref, uploadBytes } from "firebase/storage";

interface ServiceService {
  getServiceByPage: (args: PageAndSearchArgs) => Promise<PagedData<Service>>;
  getServiceSingle: (args: GetSingleArgs) => Promise<any>;
  createService: (data: Service, imageFile: File | null) => Promise<any>;
  updateService: (id: string, data: Service, image: File | null) => Promise<any>;
  deleteService: (id?: string) => Promise<void>;
}
const serviceRef = collection(db, "Services");
const service: ServiceService = {
  getServiceByPage: async ({ search, pageNo }: PageAndSearchArgs) => {
    const pageSize = 13;
    try {
      const querySnapshot = await getDocs(serviceRef);

      const services: Service[] = [];

      querySnapshot.forEach((doc) => {
        const serviceData = doc.data();
        serviceData.id = doc.id;

        services.push({
          id: serviceData.id,
          title: serviceData.title,
          category: serviceData.service,
          image: serviceData.image,
          description: serviceData.description
        });
      });
      return {
        pageNo: pageNo,
        pageSize: pageSize,
        pageCount: 1,
        itemCount: services.length,
        data: services,
      };

    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  getServiceSingle: async ({ id }: GetSingleArgs) => {
    try {
      const serviceRef = doc(db, "Services", id);
      const docSnapshot = await getDoc(serviceRef);

      if (docSnapshot.exists()) {
        const serviceData = docSnapshot.data();
        serviceData.id = docSnapshot.id;
        return serviceData as Service;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  createService: async (data: Service, imageFile: File | null) => {
    try {
       if (imageFile) {
        const storageRef = ref(storage, `serviceImages/${imageFile.name}`);

        // Upload the image to Firebase Storage
        await uploadBytes(storageRef, imageFile);
        const imageUrl = await getDownloadURL(storageRef);

        // Add the image URL to the product data
        data.image = imageUrl;
      }
      const docRef = await addDoc(serviceRef, data);
      publish(StateTopicEnum.CurrentItem, null);
      return { success: true, id: docRef.id };
    } catch (error) {
      console.error("Firebase error:", error);
      return { success: false, error: error };
    }
  },

  updateService: async (id: string, data: Service, image: File | null) => {
    try {
    const servicesRef = doc(db, "Services", id);

    // Get the current product data from Firestore
    const serviceSnapshot = await getDoc(servicesRef);
    const currentServiceData = serviceSnapshot.data();
    if (image) {
      // If a new image is provided, delete the previous image from Firebase Storage
      const previousImageUrl = currentServiceData?.image;
      if (previousImageUrl) {
        // Construct a reference to the previous image
        const previousImageRef = ref(storage, previousImageUrl);

        // Check if the previous image exists before attempting to delete it
        const imageExists = await getMetadata(previousImageRef)
          .then(() => true)
          .catch(() => false);

        if (imageExists) {
          // Delete the previous image
          await deleteObject(previousImageRef);
          console.log("Previous image deleted successfully.");
        } else {
          console.log("Previous image does not exist. No deletion needed.");
        }
      
    

      // Upload the new image to Firebase Storage with a new path
      const storageRef = ref(storage, `serviceImages/${image.name}`);
      await uploadBytes(storageRef, image);
      const imageUrl = await getDownloadURL(storageRef);

      // Update the product data with the new image URL
      data.image = imageUrl;
      }
      await setDoc(servicesRef, data);
      publish(StateTopicEnum.ReloadUpdate, null);
      return { success: true };
    } 
    
      
  
    } catch (error) {
      console.error("Firebase error:", error);
      return { success: false, error: error };
    }
  },
  deleteService: async (id?: string): Promise<void> => {
    try {
      if (!id) {
        throw new Error("Service ID is missing.");
      }

      const serviceRef = doc(db, "Services", id);

      await deleteDoc(serviceRef);
    } catch (error) {
      console.error("Firebase error:", error);
    }
  },
};

export default service;

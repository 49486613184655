import { useEffect, useRef, useState } from "react";
import {
  DatePicker,
  DynamicDropdown,
  List,
  TextField,
} from "../../../components/fields";
import { publish, useStateReducer, useSubscription } from "../../../hooks";
import { Package } from "../../../interfaces";
import service from "../../../services/Package/packageService";
import { StateTopicEnum } from "../../../enums";
import { httpsCallable } from "firebase/functions";
import bookingsService from "../../../services/Bookings/bookingsService";
import { functions } from "../../../_config/firebaseConfig";

interface AddBookingModalProps {
  booking?: any;
}
interface State {
  name?: string;
  email?: string;
  contactNumber?: string;
  packageItem?: Package | null;
  date?: string;
  noOfAdults?: string;
  noOfChildren?: string;
  message?: string;
  status?: string;
  errors: {
    name?: string;
    email?: string;
    contactNumber?: string;
    packageItem?: string;
    date?: string;
  };

  loading?: boolean;
}
export default function AddBookingModal({ booking }: AddBookingModalProps) {
  const [state, setState] = useStateReducer<State>({
    name: booking?.name ?? "",
    email: booking?.email ?? "",
    contactNumber: booking?.contactNumber ?? "",
    packageItem: booking?.packageItem ?? undefined,
    date: booking?.date ?? "",
    noOfAdults: booking?.noOfAdults ?? "1",
    noOfChildren: booking?.noOfChildren ?? "0",
    message: booking?.message ?? "",
    status: booking?.status ?? "",
    errors: {},
    loading: false,
  });
  const stateRef = useRef(state);
  const [packageList, setPackageList] = useState<Package[]>([]);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const fetchPackages = async () => {
    try {
      const response: Package[] = await service.getPackages();

      setPackageList(response ?? []);
    } catch (error) {}
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  const handleChange = (prop: string, value: any) => {
    setState({ ...state, [prop]: value });
  };

  useSubscription(StateTopicEnum.ModelSaveEvent, async () => {
    try {
      setState({ ...state, loading: true });
      var result: any;
      var entry = {
        name: stateRef.current?.name ?? "",
        email: stateRef.current?.email ?? "", // Set service title
        contactNumber: stateRef.current?.contactNumber ?? "",
        packageItem: stateRef.current.packageItem?.title ?? "",
        date: stateRef.current.date ?? "",
        noOfAdults: stateRef.current?.noOfAdults?.toString() ?? "",
        noOfChildren: stateRef.current?.noOfChildren?.toString() ?? "",
        message: stateRef.current?.message ?? "",
        status: stateRef.current?.status ?? "pending",
      } as any;

      try {
        const createEvent = httpsCallable(functions, "/createGoogleEvent");
        result = await createEvent(entry);

        if (result?.data.success === true && result?.data.id) {
          entry.id = result.data.id;
          const request = await bookingsService.postBooking(entry);
          if (request.success === true) {
            const sendEmailFunction = httpsCallable(
              functions,
              "/sendBookingEmail"
            );
            await sendEmailFunction(entry);

            alert(`Booking Placed Successfully.`);
            publish(StateTopicEnum.CurrentItem, null);
            window.location.reload();
            // Clear the form on success
            setState({
              name: "",
              email: "",
              contactNumber: "",
              packageItem: undefined,
              date: "",
              noOfAdults: "1",
              noOfChildren: "0",
              message: "",
              status: "",
              errors: {},
              loading: false,
            });
          } else {
            alert(`Failed to create Booking. Please try again.`);
          }
        } else {
          alert(`Failed to create Booking. Please try again.`);
        }
      } catch (error) {
        console.error("Error creating Booking:", error);
        alert("Failed to Send Email.");
      }
    } catch (error) {
      console.error("Error creating Booking:", error);
      alert("Failed to Book.");
    } finally {
      setState({ ...state, loading: false });
    }
  });
  return (
    <div style={{ margin: 30 }}>
      <TextField
        label="Client Name:"
        className="list-item"
        value={state?.name ?? ""}
        onChange={(e) => handleChange("name", e.target.value)}
      />
      <TextField
        label="Client Email:"
        className="list-item"
        value={state?.email ?? ""}
        onChange={(e) => handleChange("email", e.target.value)}
      />
      <TextField
        label="Client Contact Number:"
        className="list-item"
        value={state?.contactNumber ?? ""}
        onChange={(e) => handleChange("contactNumber", e.target.value)}
      />
      <List
        label="Package"
        options={packageList ?? []}
        getOptionLabel={(o) => o.title}
        isOptionEqualToValue={(o, v) => o.id === v.id}
        value={state?.packageItem ?? undefined}
        id="packageItem"
        className="list-item"
        onChange={(e, v) => {
          setState({ ...state, packageItem: v });
        }}
      />
      <DatePicker
        label="Booking Date"
        className="list-item"
        format="DD-MM-YYYY"
        value={
          state.date
            ? new Date(
                `${state?.date?.substring(0, 4)}-${state?.date?.substring(
                  5,
                  7
                )}-${state?.date?.substring(8, 10)}`
              )
            : undefined
        }
        onChange={(v) => {
          if (v) {
            const year = v.getFullYear();
            const month = String(v.getMonth() + 1).padStart(2, "0");
            const day = String(v.getDate()).padStart(2, "0");
            const fullDate = `${year}-${month}-${day}`;

            handleChange("date", fullDate);
          } else {
            handleChange("date", null);
          }
        }}
      />
      <TextField
        label="Number of Adults:"
        className="list-item"
        value={state?.noOfAdults ?? ""}
        onChange={(e) => handleChange("noOfAdults", e.target.value)}
        type="number"
      />
      <TextField
        label="Number of Children:"
        className="list-item"
        value={state?.noOfChildren ?? ""}
        onChange={(e) => handleChange("noOfChildren", e.target.value)}
        type="number"
      />
      <TextField
        label="Message or Special Request:"
        className="list-item"
        value={state?.message ?? ""}
        rows={4}
        maxRows={4}
        onChange={(e) => handleChange("message", e.target.value)}
      />
      <DynamicDropdown
        label="Status"
        value={state.status ?? null}
        options={["pending", "confirmed", "cancelled"]}
        className="class-item"
        onChange={(event: React.SyntheticEvent<Element, Event>, value: any) => {
          if (event.type !== "change") handleChange("status", value);
        }}
      />
    </div>
  );
}

import React, { useRef } from "react";
import { publish, useStateReducer, useSubscription } from "../../hooks";
import { classNameBuilder } from "../../utilities";
import { Scrollable, Button } from "../";
import "./_styles.scss";
import { ButtonBase } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { StateTopicEnum } from "../../enums";
import { Alert } from "../alert/Alert";

const modalTopic = "__modal_internal";

interface Modal {
  content: React.ReactNode;
  options?: ModalOptions;
}

interface ModalOptions {
  title?: string;
  className?: string;
  actions?: ModalAction[];
}

interface ModalAction {
  text: string;
  primary?: boolean;
  onClick: Function;
}

interface State {
  modal?: Modal | null;
  closing?: boolean;
  allowCoverClick?: boolean;
  alert?: boolean;
}

export default function Modal() {
  const [state, setState] = useStateReducer<State>({
    modal: null,
    closing: false,
    allowCoverClick: true,
    alert: false,
  });
  const { modal, allowCoverClick, closing, alert } = state;
  const closingTimeout = useRef<NodeJS.Timeout | undefined>();

  useSubscription<Modal>(modalTopic, (modal) => {
    if (!modal) return handleClose();

    setState({ modal });
    return;
  });

  useSubscription<Alert>(StateTopicEnum.Alert, (alert) =>
    setState({ alert: alert ? true : false })
  );

  const handleClose = () => {
    if (closingTimeout?.current) clearTimeout(closingTimeout.current);

    publish(StateTopicEnum.Modal, false);
    setState({ closing: true });

    closingTimeout.current = setTimeout(() => {
      setState({ closing: false, modal: null });
    }, 550);
  };

  const handleCoverClick = () => {
    if (!allowCoverClick) return;

    handleClose();
  };

  return modal ? (
    <div
      className={classNameBuilder(
        "h-app-modal-cover",
        closing ? "closing" : "",
        alert ? "has-alert" : ""
      )}
      onClick={handleCoverClick}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={classNameBuilder(
          "h-app-modal",
          modal.options?.className ?? ""
        )}
      >
        <h1 className="modal-title">
          {modal.options?.title}
          <ButtonBase onClick={handleClose} className="close-modal">
            <FontAwesomeIcon icon={faTimes} />
          </ButtonBase>
        </h1>
        <div className="modal-body">
          <Scrollable>{modal.content}</Scrollable>
        </div>
        <div className="modal-actions">
          {modal.options?.actions?.map((a, i) => (
            <Button
              key={`modal-action-${i}`}
              onClick={(e: any) => a.onClick(e)}
              text={a.text}
              primary={a.primary}
              raised={a.primary}
            />
          ))}
        </div>
      </div>
    </div>
  ) : null;
}

export function showModal({ content, options }: Modal) {
  publish(StateTopicEnum.Modal, true);
  publish(modalTopic, { content, options });
}

export function hideModal() {
  publish(modalTopic, null);
}

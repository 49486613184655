/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./_styles.scss";
import Bookings from "../../interfaces/Bookings";
import { NavigableComponent } from "../../interfaces";
import { classNameBuilder } from "../../utilities";
import { Button, PageWrapper, Scrollable } from "../../components";
import { hideSideModal, showSideModal } from "../../components/modal/SideModal";
import BookingDetailModal from "./Modal/BookingDetailModal";
import service from "../../services/Bookings/bookingsService";
import { FaPlus } from "react-icons/fa";
import AddBookingModal from "./Modal/AddBookingModal";
import { StateTopicEnum } from "../../enums";
import { publish } from "../../hooks";

interface BookingsPageProps {
  index?: boolean;
}

interface State {
  bookingItems?: Bookings[];
  selectedDate?: Date | null;
  selectedEvent?: Bookings | null;
}

export default function BookingsPage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
}: NavigableComponent & BookingsPageProps) {
  useEffect(() => {
    setBreadcrumbs([]);
    setToolbarConfig({
      allowSearch: false,
      toolbarItems: [],
    });
  }, []);

  const [state, setState] = useState<State>({
    bookingItems: [],
    selectedDate: null,
    selectedEvent: null,
  });
  const formatBookingAsEvent = (event: any) => {
    return {
      id: event?.id ?? "",
      title: event?.summary ?? "",
      start: new Date(event?.start?.dateTime ?? ""),
      end: new Date(event?.end?.dateTime ?? ""),
      description: event?.description,
      details: event, // Include the entire event details for rendering
      status: event.status,
    };
  };
  useEffect(() => {
    const loadData = async () => {
      try {
        // Fetch Google Calendar events
        const googleEvents = await service.getGoogleCalendarEvents();

        setState({
          ...state,
          bookingItems: googleEvents.map(formatBookingAsEvent),
        });
      } catch (error) {
        console.error("Error fetching Google Calendar events:", error);
      }
    };

    loadData();
  }, []);

  const events = state.bookingItems || [];

  const handleDateChange = (event: any) => {
    if (event.id) {
      const selectedEvent = state.bookingItems?.find(
        (booking) => booking.id === event.id
      );

      if (selectedEvent) {
        setState({
          ...state,
          selectedEvent,
        });
      }
    }
  };

  const handleEventSelect = async (event: any) => {
    const selectedEvent = state.bookingItems?.find(
      (booking) => booking.id === event.id
    );

    if (selectedEvent) {
      try {
        // Make an asynchronous call to retrieve booking data from your Firebase API
        const bookingDataResponse = await service.getBookingById(
          selectedEvent.id ?? ""
        );

        if (bookingDataResponse) {
          const bookingData = bookingDataResponse; // Modify this based on your API response structure

          // Merge booking data with the selected event
          const mergedEvent = {
            ...selectedEvent,
            bookingData,
          };

          setState({
            ...state,
            selectedEvent: mergedEvent.bookingData,
          });

          renderDetailsModal(mergedEvent.bookingData);
        } else {
          console.error("Failed to retrieve booking data");
        }
      } catch (error) {
        console.error("Error retrieving booking data:", error);
      }
    }
  };

  const renderDetailsModal = (data: Bookings) => {
    showSideModal({
      content: <BookingDetailModal booking={data} />,
      options: {
        title: "Booking Details",
        actions: [
          {
            text: "Delete",
            primary: true,
            onClick: async () => {
              await service.deleteBooking(data.id as string);
              alert(`Booking Deleted Successfully.`);
              publish(StateTopicEnum.CurrentItem, null);
              window.location.reload();
            },
          },
          {
            text: "Close",
            primary: true,
            onClick: () => {
              hideSideModal();
            },
          },
        ],
      },
    });
  };
  const renderAddBookingModal = () => {
    showSideModal({
      content: <AddBookingModal booking={null} />,
      options: {
        title: "Add Manual Booking",
        actions: [
          {
            text: "Save",
            primary: true,
            onClick: () => {
              publish(StateTopicEnum.ModelSaveEvent, null);
              publish(StateTopicEnum.CurrentItem, null);
              hideSideModal();
            },
          },
          {
            text: "Close",
            primary: true,
            onClick: () => {
              hideSideModal();
            },
          },
        ],
      },
    });
  };

  let classes = classNameBuilder("list-library-page", index ? "index" : "");

  moment.locale("en-GB");
  const localizer = momentLocalizer(moment);

  return (
    <PageWrapper className={classes} key={`booking-page`} secure>
      <div className="h-booking-page-content">
        <div className="library">
          <div className="booking-header">
            <h2 style={{ marginTop: "12px" }}>Bookings</h2>
            <Button
              primary
              raised
              className="btn-header"
              text="Add Record"
              icon={FaPlus}
              onClick={renderAddBookingModal}
            />
          </div>
          <Scrollable className="items">
            <div className="calendar-container">
              <Calendar
                views={["month"]}
                selectable
                onSelectSlot={(e: any) => handleDateChange(e)}
                onSelectEvent={(e: any) => handleEventSelect(e)}
                style={{ height: "70vh", color: "#333" }}
                className="custom-calendar"
                localizer={localizer}
                defaultView="month"
                events={events}
                eventPropGetter={(
                  event: any,
                  start: any,
                  end: any,
                  isSelected: any
                ) => {
                  let className = isSelected ? "selected-event" : "";

                  // Check the booking status and update the className accordingly
                  if (event.status === "confirmed") {
                    className += " confirmed-event";
                  } else if (event.status === "pending") {
                    className += " pending-event";
                  } else if (event.status === "cancelled") {
                    className += " cancelled-event";
                  }

                  return {
                    className,
                  };
                }}
              />
            </div>
          </Scrollable>
        </div>
      </div>
    </PageWrapper>
  );
}

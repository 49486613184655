import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import "./_styles.scss";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useStateReducer } from "../../../hooks";
import Message from "../../../interfaces/Messages/Message";

interface GridProps {
  rows?: Message[];
  onDelete: (row: any) => void;
}

interface RowProps {
  row: Message;
  index: number;
  selected: boolean;
  odd: boolean;
  onSelect: (row: any, index: number) => void;
  onDelete: (row: any) => void;
}

function MessageDataGridRow({ row, index, odd, selected, onDelete }: RowProps) {
  const [state] = useStateReducer<Message>({
    ...row,
  });

  return (
    <>
      <tr>
        <td
          style={{
            backgroundColor: selected ? "#C6EBFF" : odd ? "#ebedef" : "white",
          }}
        >
          <div className="displayField">{state.name ?? ""}</div>
        </td>
        <td
          style={{
            backgroundColor: selected ? "#C6EBFF" : odd ? "#ebedef" : "white",
          }}
        >
          <div className="displayField"> {state?.email ?? ""}</div>
        </td>

        <td
          style={{
            backgroundColor: selected ? "#C6EBFF" : odd ? "#ebedef" : "white",
          }}
        >
          <div className="displayField" style={{ minWidth: "200px" }}>
            {state?.message ?? ""}
          </div>
        </td>

        <td
          className="fixedColumn"
          style={{
            backgroundColor: selected ? "#C6EBFF" : odd ? "#ebedef" : "white",
          }}
        >
          {!selected && (
            <>
              <Tooltip title={"Delete Item"}>
                <IconButton
                  className="editAction"
                  size="small"
                  onClick={() => {
                    onDelete(state);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </IconButton>
              </Tooltip>
            </>
          )}
        </td>
      </tr>
    </>
  );
}

export default function MessageDataGridTable({ rows, onDelete }: GridProps) {
  const [selected, setSelected] = useState<number>();

  return (
    <div className="reviewgrid-container">
      <span className="breview-left" />
      <div>
        <table className="reviewgrid-dashboard">
          <thead>
            <tr>
              <th className="heading">Name</th>
              <th className="heading">Email</th>
              <th className="heading">Message</th>
            </tr>
          </thead>
          <tbody>
            {!!rows &&
              rows.map((d, i) => (
                <MessageDataGridRow
                  key={`row-id-${i}`}
                  row={d}
                  selected={selected === i}
                  index={i}
                  odd={i % 2 === 0}
                  onSelect={(r, i) => {
                    setSelected(i);
                  }}
                  onDelete={(r) => {
                    setSelected(undefined);
                    onDelete(r);
                  }}
                />
              ))}
          </tbody>
        </table>
      </div>
      <span className="breview-right" />
    </div>
  );
}

import React from "react";
import { Clickable } from "..";
import { classNameBuilder } from "../../utilities";
import { IconType } from "react-icons/lib";

interface SideNavLinkProps {
  to?: string;
  data?: any;
  icon?: IconType;
  children?: React.ReactNode;
  className?: string;
  isNavLink?: boolean;
}

export default function SideNavLink({
  to,
  data,
  icon: Icon,
  children,
  className,
  isNavLink,
}: SideNavLinkProps) {
  return (
    <Clickable
      className={classNameBuilder("h-app-side-nav-link", className ?? "")}
      to={to}
      data={data}
      isNavLink={isNavLink ?? true}
    >
      {Icon && <Icon />}
      {children}
    </Clickable>
  );
}

import { collection, getDocs, doc, getDoc, setDoc, addDoc, deleteDoc, query, limit } from "firebase/firestore";
import { db } from "../../_config/firebaseConfig"; // Import your Firebase Firestore instance here
import { GetSingleArgs, PageAndSearchArgs, PagedData,  } from "../../interfaces";
import { StateTopicEnum } from "../../enums";
import { publish } from "../../hooks";
import Message from "../../interfaces/Messages/Message";

interface MessageService {
  getMessageByPage: (args: PageAndSearchArgs) => Promise<PagedData<Message>>;
  getMessages: () => Promise<Message[]>;
  getMessageSingle: (args: GetSingleArgs) => Promise<any>;
  createMessage: (data: Message) => Promise<any>;
  updateMessage: (id: string, data: Message) => Promise<any>;
  deleteMessage: (id?: string) => Promise<void>;
}
const orderRef = collection(db, "Messages");
const service: MessageService = {
  getMessageByPage: async ({ search, pageNo }: PageAndSearchArgs) => {
    const pageSize = 13;
    try {
      const querySnapshot = await getDocs(orderRef);

      const reviews: Message[] = [];

      querySnapshot.forEach((doc) => {
        const reviewData = doc.data();
        reviewData.id = doc.id;

        reviews.push({
          id: reviewData.id,
          name: reviewData.name,
          message: reviewData.message,
        });
      });
      return {
        pageNo: pageNo,
        pageSize: pageSize,
        pageCount: 1,
        itemCount: reviews.length,
        data: reviews,
      };

    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  getMessages: async () => {
    const querySnapshot = await getDocs(query(orderRef, limit(10)));

    const reviews: Message[] = [];

    querySnapshot.forEach((doc) => {
    const reviewData = doc.data();
    reviewData.id = doc.id;

      reviews.push({
          id: reviewData.id,
          name: reviewData.name,
          message: reviewData.message,
        });
    });

    return reviews;
  },
  getMessageSingle: async ({ id }: GetSingleArgs) => {
    try {
      const orderRef = doc(db, "Messages", id);
      const docSnapshot = await getDoc(orderRef);

      if (docSnapshot.exists()) {
        const reviewData = docSnapshot.data();
        reviewData.id = docSnapshot.id;
        return reviewData as Message;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  createMessage: async (data: Message) => {
    try {
      
      const orderRef = collection(db, "Messages");
      const docRef = await addDoc(orderRef, data);
      publish(StateTopicEnum.ReloadUpdate, null);
      return { success: true, id: docRef.id };
    } catch (error) {
      console.error("Firebase error:", error);
      return { success: false, error: error };
    }
  },

  updateMessage: async (id: string, data: Message) => {
    try {
      
      const orderRef = doc(db, "Messages", id);
      await setDoc(orderRef, data);
      publish(StateTopicEnum.ReloadUpdate, null);
      return { success: true };
    } catch (error) {
      console.error("Firebase error:", error);
      return { success: false, error: error };
    }
  },
  deleteMessage: async (id?: string): Promise<void> => {
    try {
      if (!id) {
        throw new Error("Message ID is missing.");
      }

      const orderRef = doc(db, "Messages", id);

      await deleteDoc(orderRef);
    } catch (error) {
      console.error("Firebase error:", error);
    }
  },
};

export default service;

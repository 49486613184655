import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { NavigationContext } from "../../components/side-nav/SideNav";
import { StateTopicEnum } from "../../enums";
import { publish } from "../../hooks";
import { NavigableComponent, SettingConfig } from "../../interfaces";
import { settingService } from "../../services";
import { Library, PageWrapper } from "../../components";
import { routes } from "../../_config";
import { FaHandPointer, FaHourglass } from "react-icons/fa";

interface SettingConfigPageProps {
  index?: boolean;
}

export default function SettingConfigPage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
}: NavigableComponent & SettingConfigPageProps) {
  const { settingConfig } = useParams<string>();
  const [config, setConfig] = useState<SettingConfig>();

  useEffect(() => {
    if (index) {
      return;
    }

    const load = async () => {
      const cfg = await settingService.getSingleSetting({
        id: settingConfig ?? "",
      });

      setToolbarConfig({
        config: cfg,
        allowSearch: true,
        toolbarItems: [],
        searchPath: routes.settingCategory.go(settingConfig),
      });
      setBreadcrumbs([
        { text: "Settings", to: routes.settings.go() },
        { text: cfg.name },
      ]);
      setConfig(cfg);
    };

    load();
    setBreadcrumbs([{ text: "Settings" }]);
    publish(StateTopicEnum.NavigationContext, NavigationContext.Settings);
  }, [settingConfig]);

  if (index)
    return (
      <div className="index">
        <FaHandPointer /> Select a settings group on the left
      </div>
    );

  if (!config)
    return (
      <div className="loading">
        <FaHourglass />
        Loading...
      </div>
    );

  const meta = {
    title: `${config.name} - Settings`,
    description: `${config.name} page`,
  };

  publish(StateTopicEnum.ActiveModule, config?.module);

  return (
    <div className="h-app-settings-page-content" key={`settings-${config.id}`}>
      <PageWrapper noStyle meta={meta}>
        <Library
          config={{
            cardTemplate: config.libraryCardTemplate,
            extractKey: (a) => a.id,
            extractPath: (a) => a.id,
            searchPath: routes.settingCategory.go(settingConfig),
            service: config.services.getByPage,
          }}
        />
        <Outlet />
      </PageWrapper>
    </div>
  );
}

import { generateColorFromString } from "../../utilities";
import { Clickable } from "../";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import "./_styles.scss";
import { hideAlert, showAlert } from "../alert/Alert";
import { useAuthentication } from "../../hooks";
import { global } from "../../_config";
import { AlertTypeEnum } from "../../enums";

interface UserAccountProps {
  allowSettings?: boolean;
  onSettingsOpen?: () => void;
  onSettingsClose?: () => void;
}

export default function UserAccount({ allowSettings }: UserAccountProps) {
  const auth = useAuthentication();
  const { extractAccountNameFromUser, extractDisplayNameFromUser } =
    global.utilities;

  // Check if the user is authenticated and the user object is not null
  if (!auth || !auth.user) {
    return null; // Render nothing or a loading indicator
  }

  const displayName = extractDisplayNameFromUser(auth?.user);
  const accountName = extractAccountNameFromUser(auth?.user);
  const backgroundColor = accountName
    ? generateColorFromString(accountName, 30, 80)
    : "#ccc";
  let initials = "";

  const usernameParts = displayName?.split(" ") ?? "";

  if (usernameParts.length > 1) {
    initials = (usernameParts[0]?.[0] ?? "") + (usernameParts[1]?.[0] ?? "");
  } else {
    initials = (displayName?.[0] ?? "") + (displayName?.[1] ?? "");
  }

  const handleYesClick = () => {
    hideAlert();
    auth?.signOut(auth);
  };

  const handleSignOut = () => {
    showAlert({
      content: "Are you sure you want to sign out of your account?",
      options: {
        actions: [
          { text: "Yes", onClick: handleYesClick, primary: true },
          { text: "No", onClick: hideAlert },
        ],
        type: AlertTypeEnum.Question,
      },
    });
    return false;
  };

  return (
    <div className="h-app-user-account">
      <Clickable className="user" to="/profile" isNavLink>
        <span className="avatar" style={{ background: backgroundColor }}>
          {initials}
        </span>
        <span className="name hide-text-overflow">{displayName}</span>
        <span className="email hide-text-overflow">{accountName}</span>
      </Clickable>
      <Clickable className="sign-out" onClick={handleSignOut}>
        <FontAwesomeIcon icon={faPowerOff} />
      </Clickable>
    </div>
  );
}

import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import React, { ChangeEvent, useRef, useState } from "react";
import { asyncify, classNameBuilder, getFileType } from "../../utilities";
import { v4 as uuidv4 } from "uuid";
import Button from "../button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaTimes } from "react-icons/fa";

export interface FileValue {
  path?: string;
  file?: any;
}

interface FileUploadProps {
  id?: string;
  label: string;
  value?: FileValue;
  onChange?: (value: FileValue) => void;
  onChangeFile?: (file?: File) => void;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  error?: boolean;
  acceptedTypes?: string[];
}

export default function FileUpload({
  id,
  label,
  className,
  disabled,
  readOnly,
  value,
  acceptedTypes,
  onChange,
}: FileUploadProps) {
  const [htmlId] = useState(id ?? uuidv4());
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileValue, setFileValue] = useState<FileValue>({
    path: value?.path ?? "",
  });
  const fileName = !!fileValue.path
    ? fileValue.path.split(/(\\|\/)/g).pop()
    : "";
  const { fileClass, fileIcon } = getFileType(fileValue.path ?? "");

  const handleUploadClick = async (
    _: React.MouseEventHandler<HTMLButtonElement>
  ) => {
    await asyncify(() => fileInputRef.current?.click(), 0);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    const filePath = e.target.value;
    setFileValue({ path: filePath, file: selectedFile });

    if (onChange) {
      onChange({ path: filePath, file: selectedFile });
    }
  };

  const handleClear = () => {
    setFileValue({});
    if (onChange) {
      onChange({});
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files?.[0];
    if (file) {
      setFileValue({ path: file.name ?? "", file });
      if (onChange) {
        onChange({ path: file.name ?? "", file });
      }
    }
  };

  const preventDefault = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <FormControl
      className={classNameBuilder(
        "h-app-input-field",
        "h-app-file-upload",
        className ?? ""
      )}
    >
      <InputLabel htmlFor={htmlId}>{label}</InputLabel>
      <OutlinedInput
        onDrop={handleDrop}
        onDragOver={preventDefault}
        id={htmlId}
        label={label}
        disabled={disabled}
        readOnly
        value={fileName || "No file selected"}
        size="small"
        startAdornment={
          fileValue ? (
            <FontAwesomeIcon
              icon={fileIcon}
              className={classNameBuilder("file-icon", fileClass)}
            />
          ) : null
        }
        endAdornment={
          !fileValue?.path ? (
            <Button text="Upload" primary onClick={handleUploadClick} />
          ) : (
            <Button
              icon={FaTimes}
              primary
              onClick={handleClear}
              className="clear-button"
            />
          )
        }
      />
      <input
        ref={fileInputRef}
        type="file"
        hidden
        onChange={handleFileChange}
        // value={fileValue?.path ?? ""}
        accept={acceptedTypes?.map((t) => `.${t}`).join(",")}
      />
    </FormControl>
  );
}

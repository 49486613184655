import { Module } from "../../interfaces";
import {
  BsBell,
  BsBox,
  BsCalendar,
  BsCurrencyDollar,
  BsHouse,
  BsStars,
} from "react-icons/bs";
import { HomePage, OfferLibraryPage } from "../../pages";
import { routes } from "../../_config";
import BookingsPage from "../../pages/bookings-page/BookingsPage";
import ReviewDashboardPage from "../../pages/reviews-page/ReviewDashboardPage";
import MessageDashboardPage from "../../pages/messages-page/MessageDashboardPage";
import PackageLibraryPage from "../../pages/package-page/PackageLibraryPage";

const modules: Module[] = [
  {
    id: "home-management",
    name: "Dashboard",
    icon: BsHouse,
    component: HomePage,
    route: routes.home,
  },

  {
    id: "booking-management",
    name: "Bookings",
    icon: BsCalendar,
    component: BookingsPage,
    route: routes.booking,
  },

  {
    id: "package-management",
    name: "Packages",
    icon: BsBox,
    component: PackageLibraryPage,
    route: routes.package,
  },
  {
    id: "offer-management",
    name: "Offers",
    icon: BsCurrencyDollar,
    component: OfferLibraryPage,
    route: routes.offer,
  },
  {
    id: "communication-management",
    name: "Communication",
    icon: BsBell,
    component: ReviewDashboardPage,
    route: routes.review,
    children: [
      {
        id: "review-management",
        name: "Reviews",
        icon: BsStars,
        component: ReviewDashboardPage,
        route: routes.review,
      },
      {
        id: "message-management",
        name: "Messages",
        icon: BsBox,
        component: MessageDashboardPage,
        route: routes.message,
      },
    ],
  },
];

export default modules;

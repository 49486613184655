/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Form from "../../../components/form/Form";
import { TextField } from "../../../components/fields";
import { NavigableComponent } from "../../../interfaces";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { FaArrowLeft, FaEdit, FaTrash } from "react-icons/fa";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { routes } from "../../../_config";
import { useNavigate, useParams } from "react-router-dom";
import Service from "../../../interfaces/Service";
import serviceItem from "../../../services/ServiceItem/serviceItemService";
import { publish } from "../../../hooks";
import { Card, CardContent } from "@mui/material";
interface ViewServiceDataProps {
  service: Service;
}

export default function ViewServiceItem({
  setToolbarConfig,
  service,
}: ViewServiceDataProps & NavigableComponent) {
  const { serviceId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [];

    toolbarItems = [
      {
        label: "Back",
        icon: FaArrowLeft,
        primary: true,
        raised: true,
        onClick: () => {
          navigate(routes.service.go(), {
            replace: false,
          });
        },
      },
      "|",
      {
        label: "Edit",
        icon: FaEdit,
        primary: true,
        raised: true,
        onClick: handleEdit,
      },
      "|",
      { label: "Delete", icon: FaTrash, onClick: handleDelete },
    ];

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.service.go(serviceId),
    });
  }, []);

  const handleEdit = () => {
    navigate(routes.serviceItem.go(serviceId) + "?mode=edit", {
      replace: false,
    });
  };

  const handleDelete = () => {
    showAlert({
      content: `Deleting the service ${service?.category} now! Are you sure?`,
      options: {
        type: AlertTypeEnum.Error,
        actions: [
          {
            text: "Yes",
            primary: true,
            onClick: () => {
              hideAlert();
              serviceItem.deleteService(serviceId ?? "");
              navigate(`${routes.service.go()}`);
              publish(StateTopicEnum.CurrentItem, null);
              publish(StateTopicEnum.ReloadUpdate, null);
            },
          },
          { text: "No" },
        ],
      },
    });
  };

  return (
    <>
      <Form title="Service primary details">
        <TextField label="Service Title" value={service.title} readOnly />
        <TextField label="Service Category" value={service.category} readOnly />
        <TextField
          label="Service Description"
          value={service.description}
          readOnly
          multiline
          rows={4}
        />
        <Card sx={{ width: 250 }}>
          <CardContent>
            <img
              alt={service.title}
              style={{
                maxWidth: 250,
                maxHeight: 250,
                objectFit: "contain",
                alignSelf: "center",
              }}
              src={service.image}
            />
          </CardContent>
        </Card>
      </Form>
    </>
  );
}

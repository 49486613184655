import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { db } from "../../_config/firebaseConfig"; // Import your Firebase Firestore instance here
import { PageAndSearchArgs, PagedData } from "../../interfaces";
import Bookings from "../../interfaces/Bookings";
import { StateTopicEnum } from "../../enums";
import { publish } from "../../hooks";

interface BookingService {
  getBookingByPage: (args: PageAndSearchArgs) => Promise<PagedData<Bookings>>;
  getBookings: () => Promise<Bookings[]>;
  getBookingById: (bookingId: string) => Promise<Bookings | null>;
  getGoogleCalendarEvents: () => Promise<any>;
  postBooking: (data: Bookings) => Promise<any>;
  deleteBooking: (bookingId: string) => Promise<any>;
}
const bookingRef = collection(db, "Bookings");
const service: BookingService = {
  getBookingByPage: async ({ search, pageNo }: PageAndSearchArgs) => {
    const pageSize = 13;
    try {
      const querySnapshot = await getDocs(bookingRef);

      const bookings: Bookings[] = [];

      querySnapshot.forEach((doc) => {
        const bookingData = doc.data();
        bookingData.id = doc.id;

        bookings.push({
          id: bookingData.id,
          name: bookingData.name,
          email: bookingData.email,
          contactNumber: bookingData.contactNumber,
          packageItem: bookingData.packageItem,
          date: bookingData.date,
          noOfAdults: bookingData.noOfChildren,
          noOfChildren: bookingData.noOfChildren,
          message: bookingData.message,
          status: bookingData.status,
        });
      });
      return {
        pageNo: pageNo,
        pageSize: pageSize,
        pageCount: 1,
        itemCount: bookings.length,
        data: bookings,
      };
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  getBookingById: async (bookingId: string) => {
    try {
      const bookingRef = doc(db, "Bookings", bookingId);
      const docSnapshot = await getDoc(bookingRef);
      if (docSnapshot.exists()) {
        const bookingData = docSnapshot.data();
        bookingData.id = docSnapshot.id;
        return bookingData as Bookings;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Firebase error:", error);
      throw error;
    }
  },
  getBookings: async () => {
    const querySnapshot = await getDocs(bookingRef);

    const bookings: Bookings[] = [];

    querySnapshot.forEach((doc) => {
      const bookingData = doc.data();
      bookingData.id = doc.id;

      bookings.push({
        id: bookingData.id,
        name: bookingData.name,
        email: bookingData.email,
        contactNumber: bookingData.contactNumber,
        packageItem: bookingData.packageItem,
        date: bookingData.date,
        noOfAdults: bookingData.noOfChildren,
        noOfChildren: bookingData.noOfChildren,
        message: bookingData.message,
        status: bookingData.status,
      });
    });

    return bookings;
  },
  getGoogleCalendarEvents: async () => {
    try {
      const response = await fetch(
        "https://us-central1-sandwichtours.cloudfunctions.net/getCalendarEvents"
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      if (result && result.items) {
        return result.items;
      } else {
        throw new Error("Invalid response from Google Calendar API");
      }
    } catch (error) {
      console.error("Error fetching Google Calendar events:", error);
      throw error;
    }
  },
  postBooking: async (data: Bookings) => {
    try {
      const bookingRef = collection(db, "Bookings");

      // Use setDoc and provide the desired ID in the second parameter
      await setDoc(doc(bookingRef, data.id), data);

      publish(StateTopicEnum.CurrentItem, null);
      return { success: true, id: data.id };
    } catch (error) {
      console.error("Firebase error:", error);
      return { success: false, error: error };
    }
  },
  deleteBooking: async (eventId: string) => {
    try {
      console.log(eventId);
      // Call the function to delete the event from Google Calendar
      const response = await fetch(
        "https://us-central1-sandwichtours.cloudfunctions.net/deleteGoogleEvent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ eventId }), // Pass the eventId to the function
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // If the event deletion was successful
      const result = await response.json();
      if (result?.data?.success) {
        await deleteDoc(doc(bookingRef, eventId));
        publish(StateTopicEnum.CurrentItem, null);
        return true;
      } else {
        throw new Error("Failed to delete event from Google Calendar");
      }
    } catch (error) {
      console.error("Error deleting booking:", error);
      throw error;
    }
  },
};

export default service;

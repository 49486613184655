import React, { useRef } from "react";
import { publish, useStateReducer, useSubscription } from "../../hooks";
import { classNameBuilder } from "../../utilities";
import { Scrollable, Button } from "../";
import "./_styles.scss";
import { ButtonBase } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { StateTopicEnum } from "../../enums";
import { Alert } from "../alert/Alert";

const sideModalTopic = "__sideModal_internal";
const sideModalActionsTopic = "__sideModal_actions_internal";

interface SideModal {
  content: React.ReactNode;
  options?: SideModalOptions;
}

interface SideModalOptions {
  title?: string;
  className?: string;
  actions?: SideModalAction[];
  disallowOutsideClick?: boolean;
}

interface SideModalAction {
  text: string;
  primary?: boolean;
  onClick: Function;
}

interface State {
  sideModal?: SideModal | null;
  closing?: boolean;
  allowCoverClick?: boolean;
  alert?: boolean;
}

export default function SideModal() {
  const [state, setState] = useStateReducer<State>({
    sideModal: null,
    closing: false,
    allowCoverClick: true,
    alert: false,
  });
  const { sideModal, allowCoverClick, closing, alert } = state;
  const closingTimeout = useRef<NodeJS.Timeout | undefined>();

  useSubscription<SideModal>(sideModalTopic, (sideModal) => {
    if (!sideModal) return handleClose();

    setState({ sideModal });
    return;
  });

  useSubscription<Alert>(StateTopicEnum.Alert, (alert) =>
    setState({ alert: alert ? true : false })
  );

  const handleClose = () => {
    if (closingTimeout?.current) clearTimeout(closingTimeout.current);

    publish(StateTopicEnum.SideModal, false);
    setState({ closing: true });

    closingTimeout.current = setTimeout(() => {
      setState({ closing: false, sideModal: null });
    }, 550);
  };

  const handleCoverClick = () => {
    if (!allowCoverClick || sideModal?.options?.disallowOutsideClick) return;

    handleClose();
  };

  return sideModal ? (
    <div
      className={classNameBuilder(
        "h-app-sideModal-cover",
        closing ? "closing" : "",
        alert ? "has-sideAlert" : ""
      )}
      onClick={handleCoverClick}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={classNameBuilder(
          "h-app-sideModal",
          sideModal.options?.className ?? ""
        )}
      >
        <h1 className="sideModal-title">
          {sideModal.options?.title}
          <ButtonBase onClick={handleClose} className="close-sideModal">
            <FontAwesomeIcon icon={faTimes} />
          </ButtonBase>
        </h1>
        <div className="sideModal-body">
          <Scrollable>{sideModal.content}</Scrollable>
        </div>
        <div className="sideModal-actions">
          {sideModal.options?.actions?.map((a, i) => (
            <Button
              key={`sideModal-action-${i}`}
              onClick={(e: any) => a.onClick(e)}
              text={a.text}
              primary={a.primary}
              raised={a.primary}
            />
          ))}
        </div>
      </div>
    </div>
  ) : null;
}

export function showSideModal({ content, options }: SideModal) {
  publish(StateTopicEnum.SideModal, true);
  publish(sideModalTopic, { content, options });
}

export function hideSideModal() {
  publish(sideModalTopic, null);
}
export function updateSideModalActionState(modalActions: SideModalAction[]) {
  publish(sideModalActionsTopic, modalActions);
}

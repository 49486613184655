/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Form from "../../../components/form/Form";
import { TextField } from "../../../components/fields";
import { NavigableComponent } from "../../../interfaces";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { FaArrowLeft, FaEdit, FaTrash } from "react-icons/fa";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { routes } from "../../../_config";
import { useNavigate, useParams } from "react-router-dom";
import Category from "../../../interfaces/Category";
import service from "../../../services/Category/categoryService";
import { publish } from "../../../hooks";
interface ViewCategoryDataProps {
  category: Category;
}

export default function ViewCategoryItem({
  setToolbarConfig,
  category,
}: ViewCategoryDataProps & NavigableComponent) {
  const { categoryId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [];

    toolbarItems = [
      {
        label: "Back",
        icon: FaArrowLeft,
        primary: true,
        raised: true,
        onClick: () => {
          navigate(routes.category.go(), {
            replace: false,
          });
        },
      },
      "|",
      {
        label: "Edit",
        icon: FaEdit,
        primary: true,
        raised: true,
        onClick: handleEdit,
      },
      "|",
      { label: "Delete", icon: FaTrash, onClick: handleDelete },
    ];

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.category.go(categoryId),
    });
  }, []);

  const handleEdit = () => {
    navigate(routes.categoryItem.go(categoryId) + "?mode=edit", {
      replace: false,
    });
  };

  const handleDelete = () => {
    showAlert({
      content: `Deleting the category ${category?.category} now! Are you sure?`,
      options: {
        type: AlertTypeEnum.Error,
        actions: [
          {
            text: "Yes",
            primary: true,
            onClick: () => {
              hideAlert();
              service.deleteCategory(categoryId ?? "");
              navigate(`${routes.category.go()}`);
              publish(StateTopicEnum.CurrentItem, null);
              publish(StateTopicEnum.ReloadUpdate, null);
            },
          },
          { text: "No" },
        ],
      },
    });
  };

  return (
    <>
      <Form title="Category primary details">
        <TextField label="Category Title" value={category.category} readOnly />
      </Form>
    </>
  );
}

import React from "react";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@mui/material";
import { TextField } from "./";
import { classNameBuilder, isObject } from "../../utilities";
import "./_styles.scss";

interface DynamicDropDownProps {
  label?: string;
  value?: any;
  className?: string;
  id?: string;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined
  ) => void;
  disabled?: boolean;
  readOnly?: boolean;
  getOptionLabel?: (option: any) => string;
  getOptionValue?: (option: any) => any;
  options: any[];
  onTextChange?: any;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  style?: React.CSSProperties;
}

export default function DynamicDropdown({
  label,
  value,
  className,
  id,
  onChange,
  disabled,
  readOnly,
  getOptionLabel,
  getOptionValue,
  options,
  onTextChange,
  isOptionEqualToValue,
  style,
}: DynamicDropDownProps) {
  let val;

  if (!value) val = null;
  else if (isObject(value)) val = value;
  else if (getOptionValue) {
    val = options.filter((o) => getOptionValue(o) === value);
    if (!!val) val = val[0];
  } else {
    val = value;
  }
  return (
    <Autocomplete
      disablePortal
      id={id}
      className={classNameBuilder(
        "h-app-autocomplete-field",
        readOnly ? "readonly" : "",
        className ?? ""
      )}
      options={options}
      disabled={disabled}
      readOnly={readOnly}
      getOptionLabel={getOptionLabel}
      value={val}
      size="small"
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e: any) => onTextChange(e.target.value)}
          label={label}
        />
      )}
      style={style}
    />
  );
}

import React, { ReactNode } from "react";
import { IconType } from "react-icons";
import { Config } from "../../interfaces";
import { classNameBuilder } from "../../utilities";
import Button from "../button/Button";
import SearchBox from "../search-box/SearchBox";
import ToolSplitter from "./ToolSplitter";
import "./_styles.scss";

export interface ToolbarProps {
  item?: any;
  config?: Config | null;
  toolbarItems: (ToolItem | ToolComponent | "|")[];
  allowSearch?: boolean;
  searchPath?: string;
}

export interface ToolItem {
  label: string;
  icon?: IconType;
  disabled?: boolean;
  onClick?: () => void | Promise<void>;
  className?: string;
  primary?: boolean;
  raised?: boolean;
}

export interface ToolComponent {
  component: ReactNode;
  className?: string;
}

function determineIfToolItem(element: any): element is ToolItem {
  return (element as ToolItem).label ? true : false;
}

export default function Toolbar({
  toolbarItems,
  allowSearch,
  searchPath,
}: ToolbarProps) {
  if (!toolbarItems?.length && !allowSearch)
    return <div className="h-app-toolbar" />;

  return (
    <div className="h-app-toolbar">
      {allowSearch && <SearchBox path={searchPath} />}
      {toolbarItems.map((ti, i) => {
        if (ti === "|") return <ToolSplitter key={`tool-splitter-${i}`} />;

        if (determineIfToolItem(ti))
          return (
            <Button
              key={`tool-item-${i}-${ti.label}`}
              className={classNameBuilder(
                "h-app-tool-button",
                ti.className ?? ""
              )}
              raised={ti.raised}
              primary={ti.primary}
              icon={ti.icon}
              text={ti.label}
              onClick={ti.onClick}
              disabled={ti.disabled}
            />
          );
        else
          return (
            <div
              key={`tool-component-${i}`}
              className={classNameBuilder(
                "h-app-tool-component",
                ti.className ?? ""
              )}
            >
              {(ti as ToolComponent).component}
            </div>
          );
      })}
    </div>
  );
}

import React from "react";
import { useRef } from "react";
import { publish, useStateReducer, useSubscription } from "../../hooks";
import { classNameBuilder } from "../../utilities";
import { Button } from "../";
import "./_styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleQuestion,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { AlertTypeEnum, StateTopicEnum } from "../../enums";

const alertTopic = "__alert_internal";

export interface Alert {
  content: React.ReactNode;
  options?: AlertOptions;
}

interface AlertOptions {
  type?: AlertTypeEnum;
  className?: string;
  actions?: AlertAction[];
}

interface AlertAction {
  text: string;
  primary?: boolean;
  onClick?: Function;
}

interface AlertIconProps {
  type?: AlertTypeEnum;
}

interface State {
  alert?: Alert | null;
  closing?: boolean;
}

function AlertIcon({ type }: AlertIconProps) {
  let icon, className;

  switch (type) {
    case AlertTypeEnum.Info:
      icon = faInfoCircle;
      className = "info";
      break;
    case AlertTypeEnum.Warning:
      icon = faExclamationTriangle;
      className = "warning";
      break;
    case AlertTypeEnum.Question:
      icon = faCircleQuestion;
      className = "question";
      break;
    default:
      icon = faExclamationCircle;
      className = "error";
      break;
  }

  return <FontAwesomeIcon icon={icon} className={className} />;
}

export default function Alert() {
  const [state, setState] = useStateReducer<State>({
    alert: null,
    closing: false,
  });
  const { alert, closing } = state;
  const closingTimeout = useRef<NodeJS.Timeout | undefined>();

  useSubscription<Alert>(alertTopic, (alert) => {
    if (!alert) return handleClose();

    setState({ alert });
  });

  const handleClose = () => {
    if (closingTimeout?.current) clearTimeout(closingTimeout.current);

    publish(StateTopicEnum.Alert, false);
    setState({ closing: true });

    closingTimeout.current = setTimeout(() => {
      setState({ closing: false, alert: null });
    }, 550);
  };

  return alert ? (
    <div
      className={classNameBuilder(
        "h-app-alert-cover",
        closing ? "closing" : ""
      )}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={classNameBuilder(
          "h-app-alert",
          alert.options?.className ?? ""
        )}
      >
        <div className="alert-body">
          <AlertIcon type={alert.options?.type} />
          <div>{alert.content}</div>
        </div>
        <div className="alert-actions">
          {alert.options?.actions?.map((a, i) => (
            <Button
              key={`alert-action-${i}`}
              onClick={(e: any) => (a.onClick ? a.onClick(e) : hideAlert())}
              text={a.text}
              primary={a.primary}
              raised={a.primary}
            />
          ))}
        </div>
      </div>
    </div>
  ) : null;
}

export function showAlert({ content, options }: Alert) {
  publish(StateTopicEnum.Alert, true);
  publish(alertTopic, { content, options });
}

export function hideAlert() {
  publish(alertTopic, null);
}

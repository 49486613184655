/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { PageWrapper } from "../../components";
import { NavigationContext } from "../../components/side-nav/SideNav";
import { StateTopicEnum } from "../../enums";
import { publish, useAuthentication } from "../../hooks";
import { NavigableComponent } from "../../interfaces";
import "./_styles.scss";
import { Box, Card, Grid, Typography } from "@mui/material";
import { global } from "../../_config";

export default function ProfilePage({
  setBreadcrumbs,
  setToolbarConfig,
}: NavigableComponent) {
  useEffect(() => {
    setBreadcrumbs?.([{ text: "My Profile" }]);
    setToolbarConfig?.({ toolbarItems: [] });
    publish(StateTopicEnum.NavigationContext, NavigationContext.Modules);
  }, []);
  const auth = useAuthentication();
  const { extractDisplayNameFromUser } = global.utilities;
  const meta = { title: "My Profile" };
  const displayName = extractDisplayNameFromUser(auth?.user);

  return (
    <PageWrapper meta={meta} secure className="list-library-page">
      <Box position={"relative"}>
        <Box
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="8.75rem"
          borderRadius="16px"
          marginLeft={"24px"}
          marginRight={"24px"}
          sx={{
            backgroundColor: "#f0f0f0",
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        >
          <Card
            sx={{
              backdropFilter: `saturate(200%) blur(30px)`,
              backgroundColor: "#fff",
              boxShadow: "0px 0px 3px 3px #f0f0f0",
              position: "relative",
              mt: 0,
              mx: 3,
              py: 2,
              px: 2,
            }}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <Box height="100%" mt={0.5} lineHeight={1}>
                  <Typography variant="h5" fontWeight="medium">
                    Sandwich & Namibia Travel and Tours
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Box>
      <Box
        mb={3}
        marginLeft={"24px"}
        marginRight={"24px"}
        marginTop={"24px"}
        borderRadius="16px"
      >
        <Card>
          <Box pt={2} px={2}>
            <Box mb={0.5}>
              <Typography variant="h6" fontWeight="medium">
                User Details
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={3} width={"100%"}>
            <Grid item xs={12} md={6} xl={3} width={"100%"}>
              <Box pt={2} px={2}>
                <Box mb={0.5}>
                  <Typography variant="h6" fontWeight="regular">
                    {displayName}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h6" fontWeight="regular" color="text">
                    {auth?.user.email}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </PageWrapper>
  );
}

import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { StateTopicEnum } from "./enums";
import { AuthContext, publish, useAuthentication } from "./hooks";
import { UserContext } from "./interfaces";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { auth } from "./_config/firebaseConfig"; // Make sure you have Firebase Auth configured properly

interface ChildrenProps {
  children?: React.ReactNode;
}

interface RequiredChildrenProps {
  children: any;
}

interface State {
  user?: any | null;
  loading?: boolean;
}

export default function AuthProvider({ children }: ChildrenProps) {
  const [userState, setUserState] = useState<State>({
    user: null,
    loading: true,
  });

  const signIn = async (
    username: string,
    password: string
  ): Promise<boolean> => {
    try {
      // Use Firebase Authentication to sign in
      await signInWithEmailAndPassword(auth, username, password);
      const user = auth.currentUser;

      if (!user) {
        setUserState({ user: null, loading: false }); // Update state here
        publish(StateTopicEnum.User, null);
        return false;
      }

      setUserState({ user, loading: false }); // Update state here
      publish(StateTopicEnum.User, user);

      return true;
    } catch (error) {
      console.error("Firebase sign-in error:", error);
      return false;
    }
  };
  const signInWithGoogle = async (): Promise<boolean> => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      const user = auth.currentUser;

      if (!user) {
        setUserState({ user: null, loading: false });
        publish(StateTopicEnum.User, null);
        return false;
      }

      setUserState({ user, loading: false });
      publish(StateTopicEnum.User, user);

      return true;
    } catch (error) {
      console.error("Google Sign-In error:", error);
      return false;
    }
  };

  const signOutUser = async () => {
    try {
      // Use Firebase Authentication to sign out
      await signOut(auth);
      setUserState({ user: null, loading: false }); // Update state here
      publish(StateTopicEnum.User, null);
    } catch (error) {
      console.error("Firebase sign-out error:", error);
    }
  };

  const context: UserContext = {
    user: userState.user,
    signIn,
    signInWithGoogle,
    signOut: signOutUser,
  };

  useEffect(() => {
    // Check Firebase Authentication state
    const unsubscribe = getAuth().onAuthStateChanged((user) => {
      setUserState({ user, loading: false }); // Update state here
      publish(StateTopicEnum.User, user);
    });

    return () => unsubscribe();
  }, []); // Empty dependency array to run only once

  if (userState.loading) return <></>;

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}

export function Secure({ children }: RequiredChildrenProps) {
  const auth = useAuthentication();
  const location = useLocation();
  if (!auth?.user)
    return <Navigate to="/login" state={{ from: location }} replace />;

  return children;
}

import { LibraryCardProps } from "../../interfaces";
import Offers from "../../interfaces/Offers/Offers";

export default function OfferLibraryCard({ item }: LibraryCardProps) {
  const { offerName }: Offers = item;

  return (
    <>
      <div style={{ padding: "10px", paddingBottom: "5px", fontWeight: 600 }}>
        Package Name:
      </div>
      <div
        style={{
          padding: "10px",
          fontWeight: 400,
          paddingBottom: "5px",
          paddingLeft: "15px",
        }}
      >
        {offerName}
      </div>
    </>
  );
}

import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Bookings from "../../interfaces/Bookings";
import { useNavigate } from "react-router-dom";
import { routes } from "../../_config";

export const OverviewLatestBookings = (props: any) => {
  const { bookings = [], sx } = props;
  const navigate = useNavigate();

  return (
    <Card sx={sx}>
      <CardHeader title="Latest Bookings" />
      <Box sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Package</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Email</TableCell>
              <TableCell sortDirection="desc">Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookings.map((booking: Bookings) => {
              return (
                <TableRow hover key={booking.id}>
                  <TableCell>{booking.packageItem}</TableCell>
                  <TableCell>{booking.name}</TableCell>
                  <TableCell>{booking.email}</TableCell>
                  <TableCell>
                    {booking.date?.toString().substring(0, 10)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <Divider />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button
          color="inherit"
          size="small"
          variant="text"
          onClick={() => navigate(`${routes.booking.go()}`, { replace: false })}
        >
          View all
        </Button>
      </CardActions>
    </Card>
  );
};

OverviewLatestBookings.prototype = {
  bookings: PropTypes.array,
  sx: PropTypes.object,
};

import React, { useEffect } from "react";
import { Pagination } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useStateReducer, useSubscription } from "../../hooks";
import { getQueryParameter } from "../../utilities";
import Scrollable from "../scrollable/Scrollable";
import LibraryCard from "./LibraryCard";
import "./_styles.scss";
import { StateTopicEnum } from "../../enums";
import { PageAndSearchArgs, PagedData } from "../../interfaces";

export interface LibraryConfig<T, A extends {}> {
  searchPath: string;
  service: (args: PageAndSearchArgs & A) => Promise<PagedData<T>>;
  cardTemplate: (...args: any[]) => JSX.Element;
  extractKey: (data: T) => string;
  extractPath: (data: T) => string;
  providePagingArgs?: () => A | null;
  module?: string;
}

interface LibraryProps<T, A extends {}> {
  config: LibraryConfig<T, A>;
}

export interface SearchAndPage {
  search?: string;
  page?: number;
}

interface State {
  page?: number | string;
  search?: string;
  data?: PagedData<any> | null;
}

export default function Library<T, A extends {}>({
  config,
}: LibraryProps<T, A>) {
  const {
    searchPath,
    service,
    cardTemplate,
    extractKey,
    extractPath,
    providePagingArgs,
    module,
  } = config;
  const location = useLocation();
  const navigate = useNavigate();
  const pageQuery = getQueryParameter<number>(
    location.search,
    "page",
    parseInt
  );
  const [state, setState] = useStateReducer<State>({
    page: isNaN(parseInt(pageQuery.toString())) ? 1 : pageQuery,
    search: getQueryParameter(location.search, "search"),
    data: null,
  });
  const { pageCount, data } = state.data ?? {};
  const { search, page } = state;
  let pageNo = parseInt(page?.toString() ?? "");

  if (isNaN(pageNo)) pageNo = 1;

  useEffect(() => {
    const loadData = async () => {
      let args: any = { pageNo, search };

      if (providePagingArgs) {
        const provided: any = providePagingArgs() ?? {};

        Object.keys(provided).forEach((k) => {
          args[k] = provided[k];
        });
      }

      setState({
        data: await service(args),
      });
    };

    setState({ data: null });
    loadData();
  }, [searchPath, pageNo, search, service]);

  useSubscription<SearchAndPage>(StateTopicEnum.LibrarySearchAndPage, (v) => {
    setState({
      page: v.page,
      search: v.search,
    });
  });

  const handlePage = (_: any, p: number) => {
    let query = search ? `?search=${search}` : "";
    query += `${query ? "&" : "?"}page=${p}`;

    setState({ page: p });
    navigate(`${searchPath}${query}`, { replace: false });
  };

  return (
    <div className="h-app-library">
      <Scrollable className="items">
        <div className="h-app-library-items">
          {!data ? (
            <span className="loading">Loading...</span>
          ) : (
            data.map((d: T) => {
              const Card = cardTemplate;
              const key = extractKey(d);

              return (
                <LibraryCard key={`library-item-${key}`} to={extractPath(d)}>
                  <Card item={d} />
                </LibraryCard>
              );
            })
          )}
        </div>
      </Scrollable>
      {(pageCount ?? 0) > 1 ? (
        <Pagination
          className="pager"
          shape="rounded"
          size="small"
          page={pageNo}
          count={pageCount}
          color="primary"
          siblingCount={0}
          boundaryCount={1}
          showFirstButton
          showLastButton
          onChange={handlePage}
        />
      ) : null}
    </div>
  );
}

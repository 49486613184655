import React from "react";
import { LibraryCardProps, Product } from "../../interfaces";

export default function LibraryCard({ item }: LibraryCardProps) {
  const { title }: Product = item;
  return (
    <>
      <div
        style={{
          padding: "10px",
          fontWeight: 400,
          paddingBottom: "5px",
          paddingLeft: "15px",
        }}
      >
        {title}
      </div>
    </>
  );
}

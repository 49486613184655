/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Form from "../../../components/form/Form";
import {
  DynamicDropdown,
  FileUpload,
  InstructionWrapper,
  TextField,
} from "../../../components/fields";
import { publish, useStateReducer } from "../../../hooks";
import { getQueryParameter, validateObject } from "../../../utilities";
import { Service, NavigableComponent } from "../../../interfaces";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { FaSave, FaTimes } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routes } from "../../../_config";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import serviceItem from "../../../services/ServiceItem/serviceItemService";
import { FileValue } from "../../../components/fields/FileUpload";
import { Card, CardContent } from "@mui/material";
interface EditServiceDataProps {
  service: Service;
  image?: FileValue;
}
interface State {
  id?: string;
  title?: string;
  image?: FileValue;
  description?: string;
  category?: string;
}
export default function EditServiceItem({
  setToolbarConfig,
  service,
  image,
}: EditServiceDataProps & NavigableComponent) {
  const navigate = useNavigate();
  const { serviceId } = useParams();
  const location = useLocation();
  const mode = getQueryParameter(location.search, "mode");
  const [state, setState] = useStateReducer<State>({
    ...service,
    image: image,
  });
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);

  const stateRef = useRef(state);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const handleChange = (prop: string, value: any) => {
    setState({ [prop]: value });
  };
  const handleFileChange = (file: FileValue) => {
    if (file && file.file) {
      setSelectedImageFile(file.file);
    }
  };
  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [];

    toolbarItems = [
      {
        label: "Cancel",
        icon: FaTimes,
        onClick: handleCancel,
      },
      "|",
      {
        label: "Save",
        icon: FaSave,
        onClick: handleSave,
        primary: true,
        raised: true,
      },
    ];

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.service.go(),
    });
  }, [mode, service, selectedImageFile]);

  const handleCancel = () => {
    if (serviceId === "add")
      navigate(`${routes.service.go()}`, { replace: false });
    else
      navigate(`${routes.service.go()}/${serviceId}`, {
        replace: false,
      });
  };

  const handleSave = async () => {
    var result: any;
    var entry = {
      title: stateRef.current.title ?? "",
      description: stateRef.current.description ?? "",
      image: selectedImageFile?.toString() ?? "",
      category: stateRef.current.category ?? "",
    } as Service;

    if (serviceId === "add")
      result = await serviceItem.createService(entry, selectedImageFile);
    else
      result = await serviceItem.updateService(
        serviceId ?? "",
        entry,
        selectedImageFile
      );
    if (result?.success === true)
      showAlert({
        content: `Saving service ${state?.category} now...`,
        options: {
          type: AlertTypeEnum.Info,
          actions: [
            {
              text: "Ok",
              primary: true,
              onClick: () => {
                hideAlert();
                navigate(`${routes.serviceItem.go(serviceId)}`);
                publish(StateTopicEnum.CurrentItem, null);
              },
            },
          ],
        },
      });
    else
      showAlert({
        content: `Error saving service ${stateRef.current?.category}`,
        options: {
          type: AlertTypeEnum.Error,
          actions: [
            {
              text: "OK",
              primary: true,
              onClick: () => {
                hideAlert();
              },
            },
          ],
        },
      });
  };

  const hasError = (prop: string) => (errors[prop] ? true : false);

  const errors = validateObject(
    {
      name: { regex: /.+/, message: "Service ID number is required." },
    },
    state
  );

  return (
    <>
      <Form title="Service details">
        <InstructionWrapper error={errors["service"]}>
          <TextField
            label="Service Title"
            onChange={(e) => handleChange("title", e.target.value)}
            value={state?.title}
            error={hasError("service")}
          />
          <DynamicDropdown
            label="Service Category"
            value={state.category ?? null}
            options={["Make-Up", "Image-Consulting", "Other"]}
            className="class-item"
            onChange={(
              event: React.SyntheticEvent<Element, Event>,
              value: any
            ) => {
              if (event.type !== "change") handleChange("category", value);
            }}
          />
          <TextField
            label="Service Description"
            value={state?.description}
            onChange={(e) => handleChange("description", e.target.value)}
            multiline
            rows={4}
          />
          <FileUpload
            label="Service Image"
            value={state.image}
            acceptedTypes={["png", "jpeg", "jpg"]}
            className="file"
            onChange={(e) => handleFileChange(e)}
          />
          <Card sx={{ width: 300 }}>
            <CardContent>
              <img
                alt={state.title}
                style={{
                  maxWidth: 250,
                  maxHeight: 250,
                  objectFit: "contain",
                  alignSelf: "center",
                }}
                src={stateRef.current.image?.toString()}
              />
            </CardContent>
          </Card>
        </InstructionWrapper>
      </Form>
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { ButtonBase, InputBase } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import "./_styles.scss";
import { getQueryParameter } from "../../utilities";
import { publish } from "../../hooks";
import { StateTopicEnum } from "../../enums";

interface SearchBoxProps {
  path?: string;
}

export default function SearchBox({ path }: SearchBoxProps) {
  const location = useLocation();
  const [search, setSearch] = useState(
    getQueryParameter(location.search, "search") ?? ""
  );
  const debounceTimeout = useRef<NodeJS.Timeout | undefined>();
  const navigate = useNavigate();

  const handleSearch = (e: any) => {
    setSearch(e.target.value);

    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);

    debounceTimeout.current = setTimeout(() => {
      const query = e.target.value ? `?search=${e.target.value}&page=1` : "";
      navigate(`${path}${query}`, { replace: false });
      publish(StateTopicEnum.LibrarySearchAndPage, {
        search: e.target.value,
        page: 1,
      });
    }, 500);
  };

  const handleClearSearch = () => {
    setSearch("");

    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);

    navigate(`${path}`, { replace: false });
    publish(StateTopicEnum.LibrarySearchAndPage, {
      search: "",
      page: 1,
    });
  };

  // Register an unmount handler to clear the current search
  useEffect(() => () => publish(StateTopicEnum.LibrarySearchAndPage, ""), []);

  return (
    <InputBase
      className="h-app-search-box"
      placeholder="Search"
      value={search}
      onChange={handleSearch}
      startAdornment={<FontAwesomeIcon icon={faSearch} />}
      endAdornment={
        search ? (
          <ButtonBase className="close-search" onClick={handleClearSearch}>
            <FontAwesomeIcon icon={faClose} />
          </ButtonBase>
        ) : null
      }
    />
  );
}

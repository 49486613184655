import React from "react";
import { LibraryCardProps } from "../../interfaces";
import Package from "../../interfaces/Package";

export default function LibraryCard({ item }: LibraryCardProps) {
  const { title }: Package = item;

  return (
    <>
      <div style={{ padding: "10px", paddingBottom: "5px", fontWeight: 600 }}>
        Package Name:
      </div>
      <div
        style={{
          padding: "10px",
          fontWeight: 400,
          paddingBottom: "5px",
          paddingLeft: "15px",
        }}
      >
        {title}
      </div>
    </>
  );
}

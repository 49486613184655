import {
  usersService,
  rolesService,
} from "..";
import { SettingConfig } from "../../interfaces";
import { LibraryCard as UsersLibraryCard } from "../../pages/users-page";
import { LibraryCard as RolesLibraryCard } from "../../pages/roles-page";
import { FaUser, FaUserLock } from "react-icons/fa";

// NB!! Module property is used on SettingsItemPage to render the correct module, please use single tense and pascal case as you would when naming a File e.g: Measurable.tsx

const categories: SettingConfig[] = [
  
  {
    id: "users",
    name: "Users",
    module: "User",
    description: "Manage users and access permissions here.",
    icon: FaUser,
    services: usersService,
    libraryCardTemplate: UsersLibraryCard,
  },
  {
    id: "roles",
    name: "Roles",
    module: "Role",
    description: "Manage system roles here.",
    icon: FaUserLock,
    services: rolesService,
    libraryCardTemplate: RolesLibraryCard,
  },
];

export default categories;

/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef } from "react";
import Form from "../../../components/form/Form";
import { DynamicDropdown, TextField } from "../../../components/fields";
import { publish, useStateReducer } from "../../../hooks";
import { getQueryParameter } from "../../../utilities";
import { NavigableComponent } from "../../../interfaces";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { FaSave, FaTimes } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routes } from "../../../_config";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import service from "../../../services/Package/packageService";
import Package from "../../../interfaces/Package";
import SearchAndChips from "../../../components/fields/SearchAndChips";
import FileUpload, { FileValue } from "../../../components/fields/FileUpload";
import { Card, CardContent } from "@mui/material";
interface EditPackageDataProps {
  packageItem: Package;
  image?: FileValue;
  iteneraryFile?: FileValue;
}
interface State {
  id?: string;
  title?: string;
  subTitle?: string;
  duration?: string;
  pickup?: string;
  dropOff?: string;
  pack?: string[];
  included?: string[];
  startLocation?: string;
  conditions?: string;
  price?: string;
  discount?: string;
  description?: string;
  tag?: string;
  itenerary?: FileValue;
  image_url?: FileValue;
}
export default function EditPackageItem({
  setToolbarConfig,
  packageItem,
  image,
  iteneraryFile,
}: EditPackageDataProps & NavigableComponent) {
  const navigate = useNavigate();
  const { packageId } = useParams();
  const location = useLocation();
  const mode = getQueryParameter(location.search, "mode");

  const [state, setState] = useStateReducer<State>({
    ...packageItem,
    image_url: image,
    itenerary: iteneraryFile,
  });

  const stateRef = useRef(state);

  const defaultPickup = [
    "08h00",
    "09h00",
    "10h00",
    "11h00",
    "12h00",
    "13h00",
    "14h00",
    "15h00",
    "16h00",
    "17h00",
  ];
  const defaultDropOff = ["12h00", "13h00", "14h00", "15h00", "16h00", "17h00"];
  const defaultStartLocation = ["Walvisbay", "Swakopmund"];
  const defaultIncluded = [
    "Light Lunch",
    "Snacks",
    "Park Fees",
    "Refreshments",
  ];
  const defaultPack = ["Camera", "Hat", "Sunscreen"];

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const handleChange = (prop: string, value: any) => {
    setState({ [prop]: value });
  };
  const handleFileChange = (file: FileValue) => {
    if (file && file.file) {
      handleChange("image_url", file.file);
    }
  };
  const handleITFileChange = (file: FileValue) => {
    if (file && file.file) {
      handleChange("itenerary", file.file);
    }
  };

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [];

    toolbarItems = [
      {
        label: "Cancel",
        icon: FaTimes,
        onClick: handleCancel,
      },
      "|",
      {
        label: "Save",
        icon: FaSave,
        onClick: handleSave,
        primary: true,
        raised: true,
      },
    ];

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.package.go(),
    });
  }, [mode, packageItem]);

  const handleCancel = () => {
    if (packageId === "add")
      navigate(`${routes.package.go()}`, { replace: false });
    else
      navigate(`${routes.packageItem.go()}/${packageId}`, {
        replace: false,
      });
  };

  const handleSave = async () => {
    var result: any;
    var id: string | undefined = "";
    var entry = {
      title: stateRef.current.title ?? "",
      subTitle: stateRef.current.subTitle ?? "",
      duration: stateRef.current.duration ?? "",
      pickup: stateRef.current.pickup ?? "",
      dropOff: stateRef.current.dropOff ?? "",
      pack: stateRef.current.pack ?? "",
      included: stateRef.current.included ?? "",
      startLocation: stateRef.current.startLocation ?? "",
      price: stateRef.current.price ?? "",
      discount: stateRef.current.discount ?? "",
      conditions: stateRef.current.conditions ?? "",
      description: stateRef.current.description ?? "",
      image_url: stateRef.current.image_url ?? packageItem.image_url,
      iternerary: stateRef.current.itenerary ?? packageItem.iternerary,
      tag: stateRef.current.tag ?? "",
    } as Package;
    if (packageId === "add") {
      result = await service.createPackage(
        entry,
        stateRef.current.image_url as File,
        stateRef.current.itenerary as File
      );
      if (!!result?.id) id = result.id;
    } else {
      result = await service.updatePackage(
        packageId ?? "",
        entry,
        stateRef.current.image_url as File,
        stateRef.current.itenerary as File
      );
      id = packageId;
    }

    if (result?.success === true)
      showAlert({
        content: `Saving packageItem ${state?.title} now...`,
        options: {
          type: AlertTypeEnum.Info,
          actions: [
            {
              text: "Ok",
              primary: true,
              onClick: () => {
                hideAlert();
                navigate(`${routes.packageItem.go(id)}`);
                publish(StateTopicEnum.CurrentItem, null);
              },
            },
          ],
        },
      });
    else
      showAlert({
        content: `Error saving packageItem ${stateRef.current?.title}`,
        options: {
          type: AlertTypeEnum.Error,
          actions: [
            {
              text: "OK",
              primary: true,
              onClick: () => {
                hideAlert();
              },
            },
          ],
        },
      });
  };
  const handlePickup = useCallback(
    async (value: string) => {
      handleChange("pickup", value === "" ? undefined : value);
    },
    [state]
  );
  const handleDropOff = useCallback(
    async (value: string) => {
      handleChange("dropOff", value === "" ? undefined : value);
    },
    [state]
  );
  const handleStartLocation = useCallback(
    async (value: string) => {
      handleChange("startLocation", value === "" ? undefined : value);
    },
    [state]
  );

  return (
    <>
      <Form title="Package details">
        <TextField
          label="Package Name"
          onChange={(e) => handleChange("title", e.target.value)}
          value={state?.title}
        />
        <TextField
          label="Package Sub-Title"
          onChange={(e) => handleChange("subTitle", e.target.value)}
          value={state?.subTitle}
        />
        <DynamicDropdown
          label="Duration"
          value={state.duration ?? null}
          options={["Half Day", "Full Day", "Custom"]}
          className="class-item"
          onChange={(
            event: React.SyntheticEvent<Element, Event>,
            value: any
          ) => {
            if (event.type !== "change") handleChange("duration", value);
          }}
        />
        <SearchAndChips
          id="pickup"
          label="Pickup Time"
          values={!!state.pickup ? [state.pickup ?? ""] : []}
          getOptionLabel={(o) => o ?? ""}
          getOptionValue={(o) => o ?? ""}
          staticOptions={defaultPickup}
          onSelect={handlePickup}
          onRemove={() => handlePickup("")}
        />
        <SearchAndChips
          id="dropoff"
          label="Drop-Off Time"
          values={!!state.dropOff ? [state.dropOff ?? ""] : []}
          getOptionLabel={(o) => o ?? ""}
          getOptionValue={(o) => o ?? ""}
          staticOptions={defaultDropOff}
          onSelect={handleDropOff}
          onRemove={() => handleDropOff("")}
        />
        <SearchAndChips
          id="startLocation"
          label="Start Location"
          values={!!state.startLocation ? [state.startLocation ?? ""] : []}
          getOptionLabel={(o) => o ?? ""}
          getOptionValue={(o) => o ?? ""}
          staticOptions={defaultStartLocation}
          onSelect={handleStartLocation}
          onRemove={() => handleStartLocation("")}
        />
        <SearchAndChips
          label="Included In Tour"
          values={state.included ?? []}
          getOptionLabel={(o) => o ?? ""}
          getOptionValue={(o) => o ?? ""}
          staticOptions={defaultIncluded}
          onSelect={(v) => {
            const included = Array.isArray(state?.included)
              ? state.included
              : [];
            included.push(v);
            handleChange("included", included);
          }}
          onRemove={(_, v) => {
            const included = state.included ?? [];
            included.splice(
              included.findIndex((o) => o === v),
              1
            );
            handleChange("included", included);
          }}
        />
        <SearchAndChips
          id="pack"
          label="Items to Pack"
          values={state.pack ?? []}
          getOptionLabel={(o) => o ?? ""}
          getOptionValue={(o) => o ?? ""}
          staticOptions={defaultPack}
          onSelect={(v) => {
            const packItem = Array.isArray(state?.pack) ? state?.pack : [];
            packItem.push(v);
            handleChange("pack", packItem);
          }}
          onRemove={(_, v) => {
            const packItem = state.pack ?? [];
            packItem.splice(
              packItem.findIndex((o) => o === v),
              1
            );
            handleChange("pack", packItem);
          }}
        />
        <TextField
          label="Package Price"
          onChange={(e) => handleChange("price", e.target.value)}
          value={state?.price}
          startAdornment="N$"
        />

        <DynamicDropdown
          label="Tag"
          value={state.tag ?? null}
          options={["Popular", "Featured", "None"]}
          className="class-item"
          onChange={(
            event: React.SyntheticEvent<Element, Event>,
            value: any
          ) => {
            if (event.type !== "change") handleChange("tag", value);
          }}
        />
        <TextField
          label="Trip Conditions"
          onChange={(e) => handleChange("conditions", e.target.value)}
          value={state.conditions}
          multiline
          rows={6}
        />
        <TextField
          label="Trip Description"
          onChange={(e) => handleChange("description", e.target.value)}
          value={state.description}
          multiline
          rows={6}
          type="richtext"
        />
        <FileUpload
          label="Product Image"
          value={state.image_url}
          acceptedTypes={["png", "jpeg", "jpg", "apfs"]}
          className="file"
          onChange={(e) => handleFileChange(e)}
        />

        <FileUpload
          label="Iternerary File"
          value={state.itenerary}
          acceptedTypes={["docx", "xls", "pdf", "apfs"]}
          className="file"
          onChange={(e) => handleITFileChange(e)}
        />
        <div
          style={{
            marginTop: "24px",
            marginBottom: "24px",
          }}
        >
          <Card sx={{ width: 250, padding: "16px" }}>
            <CardContent>
              <img
                alt={packageItem.title}
                style={{
                  maxWidth: 250,
                  maxHeight: 250,
                  objectFit: "contain",
                  alignSelf: "center",
                }}
                src={packageItem.image_url}
              />
            </CardContent>
          </Card>
        </div>
      </Form>
    </>
  );
}

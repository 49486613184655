import { useRef, useState } from "react";
import { ButtonBase } from "@mui/material";
import List from "./List";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNameBuilder } from "../../utilities";
import React from "react";

interface SearchAndChipsProps {
  label?: string;
  error?: boolean;
  values?: any[];
  className?: string;
  id?: string;
  onSelect?: (value: any) => void;
  onRemove?: (label: string, value: any) => void;
  onSearch?: (search: string) => Promise<any[]>;
  disabled?: boolean;
  readOnly?: boolean;
  getOptionLabel: (option: any) => string;
  getOptionValue: (option: any) => any;
  groupBy?: (option: any) => string;
  inputValue?: string;
  placeholder?: string;
  staticOptions?: any;
}

export default function SearchAndChips({
  label,
  className,
  values,
  id,
  onSelect,
  error,
  disabled,
  readOnly,
  getOptionLabel,
  getOptionValue,
  groupBy,
  inputValue,
  placeholder,
  onSearch,
  onRemove,
  staticOptions,
}: SearchAndChipsProps) {
  const [options, setOptions] = useState<any[]>([]);
  const [inputText, setInputText] = useState("");
  const debounceTimeout = useRef<NodeJS.Timeout | undefined>();

  const handleInputChange = (value: string) => {
    setInputText(value);
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);

    debounceTimeout.current = setTimeout(async () => {
      setOptions((await onSearch?.(value)) ?? []);
    }, 500);
  };

  const handleChange = (value: any) => {
    if (Array.isArray(values)) {
      // Check if the value already exists in the array
      if (values.some((v) => getOptionValue(v) === getOptionValue(value))) {
        return;
      }
    }

    onSelect?.(value);
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && inputText.trim() !== "") {
      // If Enter is pressed and input is not empty, create a new item
      onSelect?.(inputText);
      setInputText(""); // Clear the input field
    }
  };

  return (
    <div className="h-app-search-and-chips-field">
      {readOnly ? null : (
        <List
          label={label}
          className={className}
          id={id}
          onChange={(_, v) => handleChange(v)}
          error={error}
          disabled={disabled}
          readOnly={readOnly}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          groupBy={groupBy}
          onKeyDown={handleKeyPress}
          onInputChange={(_, v) => handleInputChange(v)}
          inputValue={inputValue}
          placeholder={placeholder}
          options={staticOptions ?? options}
          blurOnSelect
          onFocus={async () => setOptions((await onSearch?.("")) ?? [])}
        />
      )}
      <div className="chips">
        {values && values.length > 0 ? (
          values.map((v) => {
            const value = getOptionValue(v);
            const display = getOptionLabel(v);

            return (
              <span
                className={classNameBuilder(
                  "chip",
                  readOnly ? "read-only" : ""
                )}
                key={`chip-${value}`}
                title={v}
              >
                {v}
                {readOnly ? null : (
                  <ButtonBase
                    className="delete-chip"
                    onClick={() => onRemove?.(display, v)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </ButtonBase>
                )}
              </span>
            );
          })
        ) : (
          <span className="chip no-data">No items selected</span>
        )}
      </div>
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Form from "../../../components/form/Form";
import {
  DynamicDropdown,
  InstructionWrapper,
  List,
  TextField,
} from "../../../components/fields";
import { publish, useStateReducer } from "../../../hooks";
import { getQueryParameter, validateObject } from "../../../utilities";
import { Product, NavigableComponent, Category } from "../../../interfaces";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { FaSave, FaTimes } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routes } from "../../../_config";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import service from "../../../services/Products/productService";
import categoryService from "../../../services/Category/categoryService";
import FileUpload, { FileValue } from "../../../components/fields/FileUpload";
interface EditProductDataProps {
  product: Product;
}
interface State {
  id?: string;
  title?: string;
}
export default function EditProductItem({
  setToolbarConfig,
  product,
}: EditProductDataProps & NavigableComponent) {
  const navigate = useNavigate();
  const { productId } = useParams();
  const location = useLocation();
  const mode = getQueryParameter(location.search, "mode");
  const [state, setState] = useStateReducer<State>({
    ...product,
  });

  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const handleChange = (prop: string, value: any) => {
    setState({ [prop]: value });
  };

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [];

    toolbarItems = [
      {
        label: "Cancel",
        icon: FaTimes,
        onClick: handleCancel,
      },
      "|",
      {
        label: "Save",
        icon: FaSave,
        onClick: handleSave,
        primary: true,
        raised: true,
      },
    ];

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.product.go(),
    });
  }, [mode, product]);

  const handleCancel = () => {
    if (productId === "add")
      navigate(`${routes.product.go()}`, { replace: false });
    else
      navigate(`${routes.product.go()}/${productId}`, {
        replace: false,
      });
  };

  const handleSave = async () => {
    var result: any;
    var entry = {
      id: stateRef.current.id ?? "",
      title: stateRef.current.title ?? "",
    } as Product;
    if (productId === "add") result = await service.createProduct(entry);
    else result = await service.updateProduct(productId ?? "", entry);
    if (result && result.success === true)
      showAlert({
        content: `Saving product ${state?.title} now...`,
        options: {
          type: AlertTypeEnum.Info,
          actions: [
            {
              text: "Ok",
              primary: true,
              onClick: () => {
                hideAlert();
                navigate(`${routes.productItem.go(productId)}`);
                publish(StateTopicEnum.CurrentItem, null);
              },
            },
          ],
        },
      });
    else
      showAlert({
        content: `Error saving product ${stateRef.current?.title}`,
        options: {
          type: AlertTypeEnum.Error,
          actions: [
            {
              text: "OK",
              primary: true,
              onClick: () => {
                hideAlert();
              },
            },
          ],
        },
      });
  };

  const hasError = (prop: string) => (errors[prop] ? true : false);

  const errors = validateObject(
    {
      name: { regex: /.+/, message: "Product ID number is required." },
    },
    state
  );

  return (
    <>
      <Form title="Product primary details">
        <TextField
          label="Product Title"
          onChange={(e) => handleChange("title", e.target.value)}
          value={state?.title}
          error={hasError("title")}
        />
      </Form>
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStateReducer, useSubscription } from "../../hooks";
import { NavigableComponent, Product } from "../../interfaces";
import { FaHandPointer, FaHourglass } from "react-icons/fa";
import { classNameBuilder, getQueryParameter } from "../../utilities";
import { routes } from "../../_config";
import { Scrollable } from "../../components";

import EditProductsItem from "./components/editProductItem";
import ViewProductsItem from "./components/viewProductItem";
import service from "../../services/Products/productService";
import { StateTopicEnum } from "../../enums";

interface ProductsItemPageProps {
  index?: boolean;
}
interface State {
  product?: Product | null;
  productId?: string;
  isSaveDisabled?: boolean;
}

export default function ProductsItemPage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
}: NavigableComponent & ProductsItemPageProps) {
  const { productId } = useParams();
  const [state, setState] = useStateReducer<State>({ isSaveDisabled: true });
  const { product } = state;
  const loading = product ? false : true;
  const location = useLocation();
  const navigate = useNavigate();
  const mode = getQueryParameter(location.search, "mode");

  const load = async () => {
    setState({ ...state, product: null });
    let product: Product = {
      id: "",
      title: "",
    };
    if (productId !== "add" && !!productId) {
      const result = await service.getProductSingle({ id: productId ?? "" });

      if (result && result.success === false) {
        navigate(routes.product.go());
      } else {
        product = result as Product;
      }
    }
    setState({ ...state, product: product });
  };

  useEffect(() => {
    setBreadcrumbs(
      index
        ? [{ text: "Products" }]
        : [
            { text: "Products", to: routes.product.raw },
            { text: state.product?.title ?? "" },
          ]
    );
    setState({ product: null, isSaveDisabled: true });
    if (productId) load();
  }, [productId, mode]);

  useSubscription(StateTopicEnum.ReloadUpdate, () => {
    load();
  });

  const classes = classNameBuilder(
    "h-app-item-page-content",
    index ? "index" : "",
    loading ? "loading" : ""
  );

  let content = <></>;

  if (index)
    content = (
      <>
        <FaHandPointer /> Select a product on the left.
      </>
    );
  else if (!state.product)
    content = (
      <>
        <FaHourglass /> Loading...
      </>
    );
  else
    content = (
      <Scrollable className="items">
        <h2>{state.product.title}</h2>

        {mode === "edit" ? (
          <EditProductsItem
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            product={state.product}
          />
        ) : (
          <ViewProductsItem
            product={state.product}
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
          />
        )}
      </Scrollable>
    );

  return (
    <div className={classes} key={`product-page-${productId}`}>
      {content}
    </div>
  );
}

/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination } from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { FaHourglass } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { Scrollable } from "../../components";
import { publish, useStateReducer } from "../../hooks";
import { NavigableComponent } from "../../interfaces";
import { getQueryParameter, classNameBuilder } from "../../utilities";
import MessageDataGridTable from "./components/MessageDataGrid";
import "./_styles.scss";
import service from "../../services/Messages/messageService";
import Message from "../../interfaces/Messages/Message";
import { showAlert, hideAlert } from "../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../enums";

interface MessageDashboardPageProps {
  index?: boolean;
}
interface State {
  review?: Message[];
  isSaveDisabled?: boolean;
  page?: number | string;
  pageCount?: number;
  search?: string;
  searchEntry?: string;
  filters?: string[];
}

export default function MessageDashboardPage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
}: NavigableComponent & MessageDashboardPageProps) {
  useEffect(() => {
    setBreadcrumbs([{ text: "Message Dashboard" }]);
    setToolbarConfig({
      allowSearch: false,
      toolbarItems: [],
    });
  }, []);
  const location = useLocation();
  const navigate = useNavigate();

  const pageQuery = getQueryParameter<number>(
    location.search,
    "page",
    parseInt
  );
  const searchQuery = getQueryParameter<number>(location.search, "search");

  const [state, setState] = useStateReducer<State>({
    page: isNaN(parseInt(pageQuery.toString())) ? 1 : pageQuery,
    search: searchQuery.toString(),
    searchEntry: searchQuery.toString(),
    filters: [],
  });

  const { page, searchEntry, search } = state;

  const loadData = async () => {
    setState({ ...state, pageCount: undefined, review: undefined });
    let filters: Map<string, string> = new Map<string, string>();
    state.filters?.map((e, i) => {
      filters.set(`status${i}`, e);
    });
    const result = await service.getMessageByPage({
      pageNo,
      search: searchQuery.toString(),
    });
    setState({
      ...state,
      pageCount: result.pageCount,
      review: result.data,
    });
  };

  let pageNo = parseInt(page?.toString() ?? "");

  if (isNaN(pageNo)) pageNo = 1;

  useEffect(() => {
    loadData();
  }, [state.page, searchEntry]);

  let classes = classNameBuilder("list-library-page");

  const { pageCount } = state;

  const handlePage = (_: any, p: number) => {
    let query = `?page=${p} ?? ""
    }&search=${search}`;
    setState({ page: p });
    navigate(`${query}`, { replace: false });
  };

  const handleDelete = async (r: Message) => {
    showAlert({
      content: `Deleting the message now! Are you sure?`,
      options: {
        type: AlertTypeEnum.Error,
        actions: [
          {
            text: "Yes",
            primary: true,
            onClick: async () => {
              hideAlert();
              await service.deleteMessage(r.id ?? "");
              publish(StateTopicEnum.CurrentItem, null);
              publish(StateTopicEnum.ReloadUpdate, null);
            },
          },
          { text: "No" },
        ],
      },
    });
  };

  let content = (
    <Scrollable>
      <div key={`company-library-page`}>
        <div className="page-content">
          <MessageDataGridTable rows={state.review} onDelete={handleDelete} />
        </div>
      </div>
    </Scrollable>
  );

  if (!state.review)
    return (
      <div className="list-library-page">
        <div className="loading">
          <FaHourglass size={40} />
        </div>
      </div>
    );
  return (
    <>
      <Helmet>
        <title>Message Dashboard</title>
      </Helmet>

      <div className={classes} key={`reviewmain-dashboard}`}>
        <div style={{ flex: "10%" }}>
          <div className="review-maine-header">
            <h2 style={{ color: "#000" }}>Message Dashboard</h2>
          </div>
          {/* <div className="list-continer">
            <div className="review-main-dashboard">
              <TextField
                label="Search"
                className="list-item"
                value={state.search}
                onChange={(e) => {
                  setState({ ...state, search: e.target.value });
                }}
              />
            </div>
            <Button
              primary
              raised
              className="btn-style"
              text="Search"
              icon={FaSearch}
              onClick={() => {
                reloadData();
              }}
            />

            <Button
              raised
              className="btn-style clear"
              text=""
              icon={FaTimes}
              onClick={() => {
                clearData();
              }}
            />
          </div> */}
        </div>
        <div style={{ flex: "90%" }}>{content}</div>
        {(pageCount ?? 0) > 1 ? (
          <Pagination
            className="pager"
            shape="rounded"
            size="small"
            page={pageNo}
            count={pageCount}
            color="primary"
            siblingCount={0}
            boundaryCount={1}
            showFirstButton
            showLastButton
            onChange={handlePage}
          />
        ) : null}
      </div>
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import Form from "../../../components/form/Form";
import { TextField } from "../../../components/fields";
import { publish, useStateReducer } from "../../../hooks";
import { getQueryParameter } from "../../../utilities";
import { NavigableComponent, Package } from "../../../interfaces";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { FaSave, FaTimes } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routes } from "../../../_config";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import Offers from "../../../interfaces/Offers/Offers";
import service from "../../../services/Offers/offerService";
import packageService from "../../../services/Package/packageService";
import "./_styles.scss";
import FileUpload, { FileValue } from "../../../components/fields/FileUpload";
interface EditOffersDataProps {
  offer: Offers;
  image?: FileValue;
}
interface State {
  id?: string;
  offerName?: string;
  discountAmount?: string;
  includedPackages?: Package[];
  expirationDate?: string;
  status?: string;

  image_url?: FileValue;
}
export default function EditOfferItem({
  setToolbarConfig,
  offer,
  image,
}: EditOffersDataProps & NavigableComponent) {
  const navigate = useNavigate();
  const { offerId } = useParams();
  const location = useLocation();
  const mode = getQueryParameter(location.search, "mode");

  const [state, setState] = useStateReducer<State>({
    ...offer,
    image_url: image,
  });
  const [packages, setPackages] = useState<Package[]>([]);
  const [selectedPackages, setSelectedPackages] = useState<Package[]>([]);

  const stateRef = useRef(state);
  const handleCheckboxChange = (packageId: string) => {
    setSelectedPackages((prevSelected) => {
      // Check if the package is already in the selected list
      const isSelected = prevSelected.some((pkg) => pkg.id === packageId);

      if (isSelected) {
        // If the package is already selected, do nothing
        return prevSelected;
      } else {
        // If the package is not selected, add it to the list
        const selectedPackage = packages.find((pkg) => pkg.id === packageId);
        return selectedPackage
          ? [...prevSelected, selectedPackage]
          : prevSelected;
      }
    });
  };

  const handleFileChange = (file: FileValue) => {
    setState({ ...state, image_url: file });
  };
  useEffect(() => {}, []);

  const loadPackages = async () => {
    let packages = await packageService.getPackages();
    if (packages.length > 0) setPackages(packages);
  };

  useEffect(() => {
    loadPackages();
  }, []);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const handleChange = (prop: string, value: any) => {
    setState({ [prop]: value });
  };

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [];

    toolbarItems = [
      {
        label: "Cancel",
        icon: FaTimes,
        onClick: handleCancel,
      },
      "|",
      {
        label: "Save",
        icon: FaSave,
        onClick: handleSave,
        primary: true,
        raised: true,
      },
    ];

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.offer.go(),
    });
  }, [mode, offer, selectedPackages, state.image_url]);

  const handleCancel = () => {
    if (offerId === "add") navigate(`${routes.offer.go()}`, { replace: false });
    else
      navigate(`${routes.offer.go()}/${offerId}`, {
        replace: false,
      });
  };

  const handleSave = async () => {
    var result: any;
    var id: string | undefined = "";
    var entry = {
      offerName: stateRef.current.offerName ?? "",
      discountAmount: stateRef.current.discountAmount ?? "",
      expirationDate: stateRef.current.expirationDate ?? "",
      status: stateRef.current.status ?? "",
      includedPackages: (selectedPackages as Package[]) ?? [],
      image_url: stateRef.current.image_url?.file,
    } as Offers;
    if (offerId === "add") {
      result = await service.createOffer(
        entry,
        stateRef.current.image_url?.file
      );
      if (!!result?.id) id = result.id;
    } else {
      result = await service.updateOffer(
        offerId ?? "",
        entry,
        stateRef.current.image_url?.file
      );
      id = offerId;
    }

    if (result?.success === true)
      showAlert({
        content: `Saving offer ${state?.offerName} now...`,
        options: {
          type: AlertTypeEnum.Info,
          actions: [
            {
              text: "Ok",
              primary: true,
              onClick: () => {
                hideAlert();
                navigate(`${routes.offer.go(id)}`);
                publish(StateTopicEnum.CurrentItem, null);
              },
            },
          ],
        },
      });
    else
      showAlert({
        content: `Error saving offer ${stateRef.current?.offerName}`,
        options: {
          type: AlertTypeEnum.Error,
          actions: [
            {
              text: "OK",
              primary: true,
              onClick: () => {
                hideAlert();
              },
            },
          ],
        },
      });
  };

  return (
    <>
      <Form title="Offers details">
        <TextField
          label="Offers Name"
          onChange={(e) => handleChange("offerName", e.target.value)}
          value={state?.offerName}
        />
        <TextField
          label="Discount Amount"
          value={state?.discountAmount}
          onChange={(e) => handleChange("discountAmount", e.target.value)}
        />
        <TextField
          label="Expiration Date"
          value={state?.expirationDate}
          onChange={(e) => handleChange("expirationDate", e.target.value)}
        />
        <TextField
          label="Status"
          value={state?.status}
          onChange={(e) => handleChange("status", e.target.value)}
        />
        <FileUpload
          label="Banner Image"
          value={state.image_url}
          acceptedTypes={["png", "jpeg", "jpg", "apfs"]}
          className="file"
          onChange={(e) => handleFileChange(e)}
        />
        <span
          style={{
            margin: "24px",
            fontSize: "22px",
            color: "#000",
            fontWeight: "600",
          }}
        >
          Check the Packages you want to include in this offer
        </span>
        <div className="package-offer-grid-container">
          {packages?.map((e) => (
            <div className="package-offer-card">
              <div className="top-section">
                <div className="date">{e?.title ?? ""}</div>
                <div className="checkbox">
                  <input
                    type="checkbox"
                    checked={selectedPackages.some((pkg) => pkg.id === e.id)}
                    onChange={() => handleCheckboxChange(e.id)}
                  />
                </div>
              </div>
              <div className="middle-section">
                <div className="heading">{e?.tag ?? ""}</div>
                <div className="sub-heading">{e?.description ?? ""}</div>
                <div className="package-offer-text">"{e?.price ?? ""}"</div>
              </div>
            </div>
          ))}
        </div>
      </Form>
    </>
  );
}

import moment from "moment";
import {
  DeleteSingleArgs,
  GetSingleArgs,
  PageAndSearchArgs,
  SearchArgs,
  UpdateSingleArgs,
} from "../interfaces";
import CrudService from "../interfaces/CrudService";
import User from "../interfaces/User";
import { asyncify } from "../utilities";
import { goFetch } from "../utilities/goFetch";

const dummyData = [...Array(10).keys()].map((v) => ({
  id: `user-${v}`,
  name: `User ${v + 1}`,
  createdOn: moment().subtract(1, "month"),
  updatedOn: moment().subtract(1, "week"),
}));

const service: CrudService = {
  getByPage: async ({ search, pageNo }: PageAndSearchArgs) => {
    const data = search
      ? dummyData.filter((d) => d.name.includes(search))
      : dummyData;
    const pageSize = 20;
    const intPageCount = Math.floor(data.length / pageSize);
    const remPageCount = data.length - intPageCount * pageSize > 0 ? 1 : 0;

    return await asyncify(
      () => ({
        pageNo,
        pageSize: pageSize,
        pageCount: intPageCount + remPageCount,
        itemCount: data.length,
        data: data.slice((pageNo - 1) * pageSize, pageNo * pageSize),
      }),
      200
    );
  },

  getSingle: async ({ id }: GetSingleArgs): Promise<User | null> => {
    const response = await goFetch(`users/${id}`).get();

    return response as User;
  },

  search: async ({ search }: SearchArgs) =>
    await asyncify(
      () => dummyData.filter((d: any) => new RegExp(search).test(d.name)),
      200
    ),

  create: async () =>
    await asyncify(() => {
      const id = `user-${dummyData.length}`;

      dummyData.push({
        id,
        name: "New",
        createdOn: moment(),
        updatedOn: moment(),
      });

      return id;
    }, 500),

  update: async ({ id: key, ...rest }: UpdateSingleArgs) =>
    await asyncify(() => {
      let toUpdate: any = dummyData.filter((d) => d.id === key);

      Object.keys(rest).map((k) => {
        toUpdate[k] = rest[k];
        return k;
      });

      delete toUpdate.isNew;

      return toUpdate;
    }, 1000),

  delete: async ({ id: key }: DeleteSingleArgs) =>
    await asyncify(async () => {
      const toDelete = await service.getSingle({ id: key });
      const index = dummyData.indexOf(toDelete);
      dummyData.splice(index, 1);
    }, 200),
};

export default service;

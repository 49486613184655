import PropTypes from "prop-types";
import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import React from "react";
import { IconType } from "react-icons";
import { BsBox } from "react-icons/bs";

export const OverviewPopularPackage = (props: any) => {
  const { sx, value } = props;
  const IconComponent = BsBox as IconType;
  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="start-flex"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              Popular Package
            </Typography>
            <Typography variant="h6">{value ?? ""}</Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: "warning.main",
              height: 56,
              width: 56,
              display: "flex",
              alignItems: "center", // Align contents (SvgIcon) in the center
            }}
          >
            <SvgIcon
              component={IconComponent}
              sx={{
                fontSize: "56px",
                width: "40px",
                height: "66px",
                marginLeft: "12px",
                marginTop: "12px",
              }}
            />
          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
};

OverviewPopularPackage.propTypes = {
  difference: PropTypes.number,
  positive: PropTypes.bool,
  value: PropTypes.string.isRequired,
  sx: PropTypes.object,
};

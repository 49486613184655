/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useEffect } from "react";
import { PageWrapper, Scrollable } from "../../components";
import { NavigableComponent, Order } from "../../interfaces";
import "./_styles.scss";
import { Box, Card, CardContent, Container, Grid } from "@mui/material";
import { OverviewBudget } from "../../components/overview/overview-budget";
import { OverviewTotalCustomers } from "../../components/overview/overview-total-customers";
import Bookings from "../../interfaces/Bookings";
import { classNameBuilder } from "../../utilities";
import { OverviewLatestBookings } from "../../components/overview/overview-latest-bookings";
import service from "../../services/Statistics/statisticsService";
import { useStateReducer } from "../../hooks";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { OverviewPopularPackage } from "../../components/overview/overview-popular-package";
import { OverviewAverageRating } from "../../components/overview/overview-average-review";
import { getRandomColor } from "../../utilities/getRandomColor";
interface HomePageProps {
  index?: boolean;
}

interface State {
  bookings?: Bookings[] | null;
  monthlyBookings?: number[] | null;
  orderItems?: Order[] | null;
  reviewCount?: any;
  revenue?: any;
  popularPackage?: any;
  bookingCount?: number;
  averageRating?: number;
}
export default function HomePage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
}: NavigableComponent & HomePageProps) {
  const [state, setState] = useStateReducer<State>({});
  const [, setLoading] = useState(true); // Add a loading state
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  useEffect(() => {
    setBreadcrumbs([]);
    setToolbarConfig({
      allowSearch: false,
      toolbarItems: [],
    });
  }, [setBreadcrumbs, setToolbarConfig]);
  const loadBookings = async () => {
    const result = await service.getBookings();
    setState({
      ...state,
      bookings: result,
    });
  };
  const loadMonthlyBookings = async () => {
    try {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const monthlyBookingsPromises = [];

      for (let month = 1; month <= 12; month++) {
        // Assuming the year is fixed for now
        const result = await service.getMonthlyBookings(currentYear, month); // Use await here
        monthlyBookingsPromises.push(result);
      }

      // Wait for all promises to resolve
      const monthlyBookingsResults = await Promise.all(monthlyBookingsPromises);
      console.log(monthlyBookingsResults);
      // Extract the count of bookings for each month
      const monthlyBookingsCounts = monthlyBookingsResults.map(
        (bookings) => bookings.length
      );
      console.log("monthlyBookingsCounts:", monthlyBookingsCounts);
      setState({
        ...state,
        monthlyBookings: monthlyBookingsCounts,
      });
      console.log(state.monthlyBookings);
    } catch (error) {
      console.error("Error fetching monthly bookings:", error);
    }
  };
  const loadRevenue = async () => {
    try {
      setState({ ...state, bookingCount: 0 });
      const result = await service.getNumberOfBookings();
      setState({
        ...state,
        bookingCount: result,
      });
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };
  const loadReviews = async () => {
    try {
      const result = await service.getReviewCount();
      setState({
        ...state,
        reviewCount: result,
      });
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };
  const loadAverageReviews = async () => {
    try {
      const result = await service.getAverageReview();
      setState({
        ...state,
        averageRating: result,
      });
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };
  const loadPopularPackage = async () => {
    try {
      const result = await service.getPopularPackage();
      setState({
        ...state,
        popularPackage: result,
      });
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };
  useEffect(() => {
    loadBookings();
    loadMonthlyBookings();
    loadPopularPackage();
    loadReviews();
    loadRevenue();
    loadAverageReviews();
  }, []);
  useEffect(() => {
    if (state.bookings !== undefined && state.orderItems !== undefined) {
      setLoading(false);
    }
  }, [state.bookings, state.orderItems]);
  let classes = classNameBuilder("list-library-page", index ? "index" : "");

  return (
    <PageWrapper className={classes} secure>
      <div className={"h-landing-page-content"} key={`booking-page`}>
        <Scrollable>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 8,
            }}
          >
            <Container maxWidth="xl">
              <Grid container spacing={3}>
                <Grid xs={12} sm={6} lg={3} item>
                  <OverviewBudget
                    difference={12}
                    positive
                    sx={{
                      height: "100%",
                      boxShadow: " -1px 1px 3px 3px #f0f0f0",
                      borderRadius: "16px",
                    }}
                    value={state.bookingCount}
                  />
                </Grid>
                <Grid xs={12} sm={6} lg={3} item>
                  <OverviewTotalCustomers
                    difference={16}
                    positive={false}
                    sx={{
                      height: "100%",
                      boxShadow: " -1px 1px 3px 3px #f0f0f0",
                      borderRadius: "16px",
                    }}
                    value={state.reviewCount}
                  />
                </Grid>
                <Grid xs={12} sm={6} lg={3} item>
                  <OverviewAverageRating
                    difference={16}
                    positive={false}
                    sx={{
                      height: "100%",
                      boxShadow: " -1px 1px 3px 3px #f0f0f0",
                      borderRadius: "16px",
                    }}
                    value={state.averageRating?.toString() ?? ""}
                  />
                </Grid>
                <Grid xs={12} sm={6} lg={3} item>
                  <OverviewPopularPackage
                    difference={16}
                    positive={false}
                    sx={{
                      height: "100%",
                      boxShadow: " -1px 1px 3px 3px #f0f0f0",
                      borderRadius: "16px",
                    }}
                    value={state.popularPackage}
                  />
                </Grid>

                <Grid xs={12} sm={6} lg={12} item>
                  <BarChart monthlyBookings={state.monthlyBookings} />
                </Grid>
                <Grid xs={12} sm={6} lg={12} item>
                  <OverviewLatestBookings
                    bookings={state.bookings as Bookings[]}
                    sx={{
                      height: "100%",
                      boxShadow: " -1px 1px 3px 3px #f0f0f0",
                      borderRadius: "16px",
                    }}
                  />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Scrollable>
      </div>
    </PageWrapper>
  );
}
function BarChart({ monthlyBookings }: any) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const randomColors = months.map(() => getRandomColor());

  const data = {
    labels: months,
    datasets: [
      {
        label: "Monthly Bookings",
        data: monthlyBookings,
        backgroundColor: randomColors,
        borderColor: randomColors.map((color) => color.replace("0.6", "1")), // Adjust alpha for borderColor
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Monthly Bookings",
      },
    },
  } as const;

  return (
    <Card
      sx={{
        backgroundColor: "#fff",
        boxShadow: " -1px 1px 3px 3px #f0f0f0",
        borderRadius: "16px",
      }}
    >
      <CardContent>
        {" "}
        <Bar data={data} options={options} />{" "}
      </CardContent>
    </Card>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStateReducer, useSubscription } from "../../hooks";
import { NavigableComponent, Category } from "../../interfaces";
import { FaHandPointer, FaHourglass } from "react-icons/fa";
import { classNameBuilder, getQueryParameter } from "../../utilities";
import { routes } from "../../_config";
import { Scrollable } from "../../components";

import EditCategoryItem from "./components/EditCategoryItem";
import ViewCategoryItem from "./components/ViewCategoryItem";
import service from "../../services/Category/categoryService";
import { StateTopicEnum } from "../../enums";

interface CategoryItemPageProps {
  index?: boolean;
}
interface State {
  category?: Category | null;
  categoryId?: string;
  isSaveDisabled?: boolean;
}

export default function CategoryItemPage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
}: NavigableComponent & CategoryItemPageProps) {
  const { categoryId } = useParams();
  const [state, setState] = useStateReducer<State>({ isSaveDisabled: true });
  const { category } = state;
  const loading = category ? false : true;
  const location = useLocation();
  const navigate = useNavigate();
  const mode = getQueryParameter(location.search, "mode");

  const load = async () => {
    setState({ ...state, category: null });
    let category: Category = {
      id: "",
      category: "",
    };
    if (categoryId !== "add" && !!categoryId) {
      const result = await service.getCategorySingle({ id: categoryId ?? "" });

      if (result.success === false) navigate(routes.category.go());
      else {
        category = result as Category;
      }
    }
    setState({ ...state, category: category });
  };

  useEffect(() => {
    setBreadcrumbs(
      index
        ? [{ text: "Category" }]
        : [
            { text: "Category", to: routes.category.raw },
            { text: state.category?.category ?? "" },
          ]
    );
    setState({ category: null, isSaveDisabled: true });
    if (categoryId) load();
  }, [categoryId, mode]);

  useSubscription(StateTopicEnum.ReloadUpdate, () => {
    load();
  });

  const classes = classNameBuilder(
    "h-app-item-page-content",
    index ? "index" : "",
    loading ? "loading" : ""
  );

  let content = <></>;

  if (index)
    content = (
      <>
        <FaHandPointer /> Select a category on the left.
      </>
    );
  else if (!state.category)
    content = (
      <>
        <FaHourglass /> Loading...
      </>
    );
  else
    content = (
      <Scrollable className="items">
        <h2>{state.category.category}</h2>

        {mode === "edit" ? (
          <EditCategoryItem
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            category={state.category}
          />
        ) : (
          <ViewCategoryItem
            category={state.category}
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
          />
        )}
      </Scrollable>
    );

  return (
    <div className={classes} key={`category-page-${categoryId}`}>
      {content}
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import Form from "../../../components/form/Form";
import {
  DynamicDropdown,
  SearchAndChips,
  TextField,
} from "../../../components/fields";
import { NavigableComponent } from "../../../interfaces";
import { ToolItem } from "../../../components/toolbar/Toolbar";
import { FaArrowLeft, FaEdit, FaTrash } from "react-icons/fa";
import { hideAlert, showAlert } from "../../../components/alert/Alert";
import { AlertTypeEnum, StateTopicEnum } from "../../../enums";
import { routes } from "../../../_config";
import { useNavigate, useParams } from "react-router-dom";
import Package from "../../../interfaces/Package";
import service from "../../../services/Package/packageService";
import { publish } from "../../../hooks";
import { Card, CardContent } from "@mui/material";
interface ViewPackageDataProps {
  packageItem: Package;
}

export default function ViewPackageItem({
  setToolbarConfig,
  packageItem,
}: ViewPackageDataProps & NavigableComponent) {
  const { packageId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [];

    toolbarItems = [
      {
        label: "Back",
        icon: FaArrowLeft,
        primary: true,
        raised: true,
        onClick: () => {
          navigate(routes.package.go(), {
            replace: false,
          });
        },
      },
      "|",
      {
        label: "Edit",
        icon: FaEdit,
        primary: true,
        raised: true,
        onClick: handleEdit,
      },
      "|",
      { label: "Delete", icon: FaTrash, onClick: handleDelete },
    ];

    setToolbarConfig({
      allowSearch: true,
      toolbarItems,
      searchPath: routes.package.go(packageId),
    });
  }, []);

  const handleEdit = () => {
    navigate(routes.packageItem.go(packageId) + "?mode=edit", {
      replace: false,
    });
  };

  const handleDelete = () => {
    showAlert({
      content: `Deleting the packageItem ${packageItem?.title} now! Are you sure?`,
      options: {
        type: AlertTypeEnum.Error,
        actions: [
          {
            text: "Yes",
            primary: true,
            onClick: () => {
              hideAlert();
              service.deletePackage(packageId ?? "");
              navigate(`${routes.package.go()}`);
              publish(StateTopicEnum.CurrentItem, null);
            },
          },
          { text: "No" },
        ],
      },
    });
  };

  return (
    <>
      <Form title="Package primary details">
        <TextField label="Package Name" readOnly value={packageItem?.title} />
        <TextField
          label="Package Sub-Title"
          readOnly
          value={packageItem?.subTitle}
        />
        <DynamicDropdown
          label="Duration"
          value={packageItem?.duration ?? null}
          options={["Half Day", "Full Day", "Custom"]}
          className="class-item"
          readOnly
        />
        <SearchAndChips
          id="pickup"
          label="Pickup Time"
          values={!!packageItem?.pickup ? [packageItem?.pickup ?? ""] : []}
          getOptionLabel={(o) => o ?? ""}
          getOptionValue={(o) => o ?? ""}
          staticOptions={packageItem.pickup}
          readOnly
        />
        <SearchAndChips
          id="dropoff"
          label="Drop-Off Time"
          values={!!packageItem?.dropOff ? [packageItem?.dropOff ?? ""] : []}
          getOptionLabel={(o) => o ?? ""}
          getOptionValue={(o) => o ?? ""}
          staticOptions={packageItem.dropOff}
          readOnly
        />
        <SearchAndChips
          id="startLocation"
          label="Start Location"
          values={
            !!packageItem?.startLocation
              ? [packageItem?.startLocation ?? ""]
              : []
          }
          staticOptions={packageItem.startLocation}
          getOptionLabel={(o) => o ?? ""}
          getOptionValue={(o) => o ?? ""}
          readOnly
        />
        <SearchAndChips
          label="Included In Tour"
          values={packageItem?.included ?? []}
          getOptionLabel={(o) => o ?? ""}
          getOptionValue={(o) => o ?? ""}
          staticOptions={packageItem.included}
          readOnly
        />
        <SearchAndChips
          id="pack"
          label="Items to Pack"
          values={packageItem?.pack ?? []}
          getOptionLabel={(o) => o ?? ""}
          getOptionValue={(o) => o ?? ""}
          staticOptions={packageItem.pack}
          readOnly
        />
        <TextField
          label="Package Price"
          readOnly
          value={packageItem?.price}
          startAdornment="N$"
        />

        <DynamicDropdown
          label="Tag"
          value={packageItem?.tag ?? null}
          options={["Popular", "Featured", "None"]}
          className="class-item"
          readOnly
        />
        <TextField
          label="Trip Conditions"
          readOnly
          value={packageItem?.conditions}
          multiline
          rows={6}
        />
        <TextField
          label="Trip Description"
          readOnly
          value={packageItem?.description}
          multiline
          rows={6}
          type="richtext"
        />
        <div
          style={{
            marginTop: "24px",
            marginBottom: "24px",
          }}
        >
          <Card sx={{ width: 280, padding: "16px" }}>
            <CardContent>
              <img
                alt={packageItem.title}
                style={{
                  maxWidth: 250,
                  maxHeight: 250,
                  objectFit: "contain",
                  alignSelf: "center",
                }}
                src={packageItem.image_url}
              />
            </CardContent>
          </Card>
        </div>
      </Form>
    </>
  );
}

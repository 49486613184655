import { classNameBuilder } from "../../utilities";
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "./TextField";

interface DatePickerProps {
  id?: string;
  error?: boolean;
  label?: string;
  value?: Date;
  format?: string;
  className?: string;
  onChange?: (value?: Date) => void;
  disabled?: boolean;
  readOnly?: boolean;
  maxDate?: Dayjs;
  disableFuture?: boolean;
  minDate?: Dayjs;
}

export default function DatePicker({
  label,
  className,
  id,
  onChange,
  error,
  format,
  disabled,
  value,
  readOnly,
  maxDate,
  minDate,
  disableFuture = false,
  ...props
}: DatePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MUIDatePicker
        {...props}
        className={classNameBuilder(
          "h-app-date-field",
          readOnly ? "readonly" : "",
          className ?? ""
        )}
        inputFormat={!format ? "DD/MM/YYYY" : format}
        label={label}
        value={!!value ? dayjs(value) : null}
        onChange={(value) =>
          !!onChange && typeof value !== typeof String
            ? onChange((value as Dayjs)?.toDate() ?? undefined)
            : function () {}
        }
        disabled={disabled}
        readOnly={readOnly}
        disableFuture={disableFuture}
        maxDate={maxDate}
        minDate={minDate}
        renderInput={TextField}
      />
    </LocalizationProvider>
  );
}

enum StateTopicEnum {
  Alert = "_alert",
  LibraryPage = "_libraryPage",
  LibrarySearch = "_librarySearch",
  LibrarySearchAndPage = "_librarySearchAndPage",
  Modal = "_modal",
  SideModal = "_sideModal",
  ModelSaveEvent = "_modelSaveEvent",
  ModelUpdateEvent = "_modelUpdateEvent",
  NavigationContext = "_navigationContext",
  User = "_user",
  Site = "_site",
  ActiveModule = "_activeModule",
  CurrentItem = "_currentItem",
  ReloadUpdate = "_reloadEvent",
  Drawer = "_drawer",
}

export default StateTopicEnum;

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Library, PageWrapper } from "../../components";
import { NavigableComponent } from "../../interfaces";
import { routes } from "../../_config";
import LibraryCard from "./LibraryCard";
import "./_styles.scss";
import service from "../../services/ServiceItem/serviceItemService";
import { ToolItem } from "../../components/toolbar/Toolbar";
import { FaPlus } from "react-icons/fa";

export default function ServiceLibraryPage({
  setToolbarConfig,
}: NavigableComponent) {
  const navigate = useNavigate();
  const { serviceId } = useParams();

  useEffect(() => {
    let toolbarItems: (ToolItem | "|")[] = [];
    toolbarItems.push({
      label: "Add",
      icon: FaPlus,
      primary: true,
      raised: true,
      onClick: async () => {
        navigate(`${routes.serviceItem.go("add")}?mode=edit`);
      },
    });
    setToolbarConfig({
      allowSearch: true,
      searchPath: routes.service.go(),
      toolbarItems: toolbarItems,
    });
  }, [serviceId]);

  const meta = { title: "Service" };

  return (
    <PageWrapper meta={meta} className="h-service-library-page" secure>
      <div
        className="h-service-library-page-content"
        key={`service-library-page`}
      >
        <PageWrapper noStyle meta={meta}>
          <Library
            config={{
              cardTemplate: LibraryCard,
              extractKey: (a) => a.id,
              extractPath: (a) => a.id,
              searchPath: routes.service.go(),
              service: service.getServiceByPage,
            }}
          />
          <Outlet />
        </PageWrapper>
      </div>
    </PageWrapper>
  );
}

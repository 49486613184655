/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStateReducer, useSubscription } from "../../hooks";
import { NavigableComponent } from "../../interfaces";
import { FaHandPointer, FaHourglass } from "react-icons/fa";
import { classNameBuilder, getQueryParameter } from "../../utilities";
import { routes } from "../../_config";
import { Scrollable } from "../../components";

import EditPackageItem from "./components/EditPackageItem";
import ViewPackageItem from "./components/ViewPackageItem";
import service from "../../services/Package/packageService";
import { StateTopicEnum } from "../../enums";
import Package from "../../interfaces/Package";

interface PackageItemPageProps {
  index?: boolean;
}
interface State {
  packageItem?: Package | null;
  packageId?: string;
  isSaveDisabled?: boolean;
}

export default function PackageItemPage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
}: NavigableComponent & PackageItemPageProps) {
  const { packageId } = useParams();
  const [state, setState] = useStateReducer<State>({ isSaveDisabled: true });
  const { packageItem } = state;
  const loading = packageItem ? false : true;
  const location = useLocation();
  const navigate = useNavigate();
  const mode = getQueryParameter(location.search, "mode");

  const load = async () => {
    setState({ ...state, packageItem: null });
    let packageItem: Package = {
      id: "",
      title: "",
    };
    if (packageId !== "add" && !!packageId) {
      const result = await service.getPackageSingle({
        id: packageId ?? "",
      });

      if (result.success === false) navigate(routes.package.go());
      else {
        packageItem = result as Package;
      }
    }
    setState({ ...state, packageItem: packageItem });
  };

  useEffect(() => {
    setBreadcrumbs(
      index
        ? [{ text: "Package" }]
        : [
            { text: "Package", to: routes.package.raw },
            { text: state.packageItem?.title ?? "" },
          ]
    );
    setState({ packageItem: null, isSaveDisabled: true });
    if (packageId) load();
  }, [packageId, mode]);

  useSubscription(StateTopicEnum.CurrentItem, () => {
    load();
  });

  const classes = classNameBuilder(
    "h-app-item-page-content",
    index ? "index" : "",
    loading ? "loading" : ""
  );

  let content = <></>;

  if (index)
    content = (
      <>
        <FaHandPointer /> Select a packageItem on the left.
      </>
    );
  else if (!state.packageItem)
    content = (
      <>
        <FaHourglass /> Loading...
      </>
    );
  else
    content = (
      <Scrollable className="items">
        <h2>{state.packageItem.title}</h2>

        {mode === "edit" ? (
          <EditPackageItem
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            packageItem={state.packageItem}
          />
        ) : (
          <ViewPackageItem
            packageItem={state.packageItem}
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
          />
        )}
      </Scrollable>
    );

  return (
    <div className={classes} key={`packageItem-page-${packageId}`}>
      {content}
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStateReducer, useSubscription } from "../../hooks";
import { NavigableComponent, Service } from "../../interfaces";
import { FaHandPointer, FaHourglass } from "react-icons/fa";
import { classNameBuilder, getQueryParameter } from "../../utilities";
import { routes } from "../../_config";
import { Scrollable } from "../../components";

import EditServiceItem from "./components/EditServiceItem";
import ViewServiceItem from "./components/ViewServiceItem";
import serviceItem from "../../services/ServiceItem/serviceItemService";
import { StateTopicEnum } from "../../enums";

interface ServiceItemPageProps {
  index?: boolean;
}
interface State {
  service?: Service | null;
  serviceId?: string;
  isSaveDisabled?: boolean;
}

export default function ServiceItemPage({
  setBreadcrumbs,
  setToolbarConfig,
  index,
}: NavigableComponent & ServiceItemPageProps) {
  const { serviceId } = useParams();
  const [state, setState] = useStateReducer<State>({ isSaveDisabled: true });
  const { service } = state;
  const loading = service ? false : true;
  const location = useLocation();
  const navigate = useNavigate();
  const mode = getQueryParameter(location.search, "mode");

  const load = async () => {
    setState({ ...state, service: null });
    let service: Service = {
      id: "",
      category: "",
    };
    if (serviceId !== "add" && !!serviceId) {
      const result = await serviceItem.getServiceSingle({
        id: serviceId ?? "",
      });

      if (result.success === false) navigate(routes.service.go());
      else {
        service = result as Service;
      }
    }
    setState({ ...state, service: service });
  };

  useEffect(() => {
    setBreadcrumbs(
      index
        ? [{ text: "Service" }]
        : [
            { text: "Service", to: routes.service.raw },
            { text: state.service?.category ?? "" },
          ]
    );
    setState({ service: null, isSaveDisabled: true });
    if (serviceId) load();
  }, [serviceId, mode]);

  useSubscription(StateTopicEnum.ReloadUpdate, () => {
    load();
  });

  const classes = classNameBuilder(
    "h-app-item-page-content",
    index ? "index" : "",
    loading ? "loading" : ""
  );

  let content = <></>;

  if (index)
    content = (
      <>
        <FaHandPointer /> Select a service on the left.
      </>
    );
  else if (!state.service)
    content = (
      <>
        <FaHourglass /> Loading...
      </>
    );
  else
    content = (
      <Scrollable className="items">
        <h2>{state.service.category}</h2>

        {mode === "edit" ? (
          <EditServiceItem
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
            service={state.service}
          />
        ) : (
          <ViewServiceItem
            service={state.service}
            setToolbarConfig={setToolbarConfig}
            setBreadcrumbs={setBreadcrumbs}
          />
        )}
      </Scrollable>
    );

  return (
    <div className={classes} key={`service-page-${serviceId}`}>
      {content}
    </div>
  );
}

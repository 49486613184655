import React from "react";
import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { PageWrapper } from "../../components";
import { NavigationContext } from "../../components/side-nav/SideNav";
import { StateTopicEnum } from "../../enums";
import { publish } from "../../hooks";
import { NavigableComponent } from "../../interfaces";
import "./_styles.scss";

export default function SettingsPage({
  setBreadcrumbs,
  setToolbarConfig,
}: NavigableComponent) {
  const { settingConfig } = useParams();

  useEffect(() => {
    if (!settingConfig) {
      setBreadcrumbs?.([{ text: "Settings" }]);
      setToolbarConfig?.({ toolbarItems: [] });
    }

    publish(StateTopicEnum.NavigationContext, NavigationContext.Settings);
  }, [settingConfig]);

  const meta = { title: "Settings" };

  return (
    <PageWrapper meta={meta} className="h-app-settings-page" secure>
      <Outlet />
    </PageWrapper>
  );
}

const config = {
  applicationName: "SandwichAdminPortal",
  utilities: {
    extractAccountNameFromUser: (user: any) => user.email,
    extractDisplayNameFromUser: (user: any) => user.displayName,
  },
  tokens: {
    mapBox:
      "pk.eyJ1Ijoid2VybmVyLXR5cGVkZXYiLCJhIjoiY2w2a2UwY2NhMDB3YTNubWVqMHBtMGlzNSJ9.H8igM52nSceLGjLQivEjzQ",
  },
};

export default config;

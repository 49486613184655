import PropTypes from "prop-types";
import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import React from "react";
import { IconType } from "react-icons";
import { BsPerson } from "react-icons/bs";

export const OverviewTotalCustomers = (props: any) => {
  const { sx, value } = props;
  const IconComponent = BsPerson as IconType;
  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              Total Reviews
            </Typography>
            <Typography variant="h4">{value}</Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: "success.main",
              height: 56,
              width: 56,
            }}
          >
            <SvgIcon
              component={IconComponent}
              sx={{
                fontSize: "56px",
                width: "40px",
                height: "66px",
                marginLeft: "12px",
                marginTop: "12px",
              }}
            />
          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
};

OverviewTotalCustomers.propTypes = {
  difference: PropTypes.number,
  positive: PropTypes.bool,
  value: PropTypes.string.isRequired,
  sx: PropTypes.object,
};

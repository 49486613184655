import React from "react";
import {
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
} from "@mui/material";
import { useEffect, useRef } from "react";
import { classNameBuilder } from "../../utilities";

class ExtendedTextFieldProps {
  readOnly?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  inputComponent?: any;
  focus?: boolean;
}

type TextFieldProps = MUITextFieldProps & ExtendedTextFieldProps;

export { TextFieldProps };

export default function TextField({
  label,
  value,
  className,
  id,
  onChange,
  onKeyDown,
  error,
  disabled,
  autoComplete,
  readOnly,
  startAdornment,
  endAdornment,
  inputComponent,
  type,
  placeholder,
  focus,
  InputProps,
  multiline,
  ...props
}: TextFieldProps) {
  const fieldRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (focus) {
      const currentFieldRef = fieldRef.current;
      const firstChild = currentFieldRef?.children[0]?.children[0];
      const secondChild = currentFieldRef?.children[0]?.children[1];
      const toFocus =
        firstChild?.toString() === "[object HTMLInputElement]"
          ? firstChild
          : secondChild;

      (toFocus as HTMLInputElement)?.focus();
    }
  }, [focus]);

  return (
    <MUITextField
      {...props}
      ref={fieldRef}
      id={id}
      type={type}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      className={classNameBuilder(
        "h-app-input-field",
        readOnly ? "readonly" : "",
        className ?? ""
      )}
      size="small"
      error={error}
      disabled={disabled}
      autoComplete={autoComplete}
      multiline={multiline}
      InputProps={{
        ...InputProps,
        readOnly: readOnly,
        startAdornment: startAdornment || InputProps?.startAdornment,
        endAdornment: endAdornment || InputProps?.endAdornment,
        inputComponent: inputComponent || InputProps?.inputComponent,
        autoComplete: "new-password",
      }}
    />
  );
}

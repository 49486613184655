import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../_config/firebaseConfig"; // Import your Firebase Firestore instance here
import Bookings from "../../interfaces/Bookings";

interface StatisticsService {
  getBookings: () => Promise<Bookings[]>;
  getMonthlyBookings: (year: number, month: number) => Promise<Bookings[]>;
  getPopularPackage: () => Promise<any>;
  getReviewCount: () => Promise<any>;
  getNumberOfBookings: () => Promise<any>;
  getAverageReview: () => Promise<number>;
}
const bookingRef = collection(db, "Bookings");
const reviewRef = collection(db, "Reviews");
const service: StatisticsService = {
  getBookings: async () => {
    const querySnapshot = await getDocs(
      query(bookingRef, orderBy("date", "desc"), limit(10))
    );

    const bookings: Bookings[] = [];

    querySnapshot.forEach((doc) => {
      const bookingData = doc.data();
      bookingData.id = doc.id;

      bookings.push({
        id: bookingData.id,
        name: bookingData.name,
        email: bookingData.email,
        contactNumber: bookingData.contactNumber,
        packageItem: bookingData.packageItem,
        date: bookingData.date,
        noOfAdults: bookingData.noOfChildren,
        noOfChildren: bookingData.noOfChildren,
      });
    });

    return bookings;
  },
  getMonthlyBookings: async (year: number, month: number) => {
    // Assuming 'date' is a Timestamp field in Firestore
    const startOfMonthString = `${year}-${month
      .toString()
      .padStart(2, "0")}-01`;
    const endOfMonthString = `${year}-${month.toString().padStart(2, "0")}-31`; // Assuming 31 days in a month for simplicity

    const querySnapshot = await getDocs(
      query(
        bookingRef,
        where("date", ">=", startOfMonthString),
        where("date", "<=", endOfMonthString)
      )
    );

    const bookings: Bookings[] = [];

    querySnapshot.forEach((doc) => {
      const bookingData = doc.data();
      bookingData.id = doc.id;

      bookings.push({
        id: bookingData.id,
        name: bookingData.name,
        email: bookingData.email,
        contactNumber: bookingData.contactNumber,
        packageItem: bookingData.packageItem,
        date: new Date(bookingData.date).toString(), // Convert string to JavaScript Date
        noOfAdults: bookingData.noOfAdults,
        noOfChildren: bookingData.noOfChildren,
      });
    });
    return bookings;
  },

  getPopularPackage: async () => {
    const querySnapshot = await getDocs(bookingRef);

    const packageCountMap: Record<string, number> = {};

    querySnapshot.forEach((doc) => {
      const bookingData = doc.data();
      const packageName = bookingData.packageItem;

      // Increment the count for the package in the map
      packageCountMap[packageName] = (packageCountMap[packageName] || 0) + 1;
    });

    // Find the most popular package
    let mostPopularPackage: string | null = null;
    let maxCount = 0;

    Object.entries(packageCountMap).forEach(([packageName, count]) => {
      if (count > maxCount) {
        mostPopularPackage = packageName;
        maxCount = count;
      }
    });

    return mostPopularPackage;
  },
  getNumberOfBookings: async () => {
    const querySnapshot = await getDocs(bookingRef);

    // Get the number of customers
    const bookingCount = querySnapshot.size;

    return bookingCount;
  },
  getReviewCount: async () => {
    const querySnapshot = await getDocs(reviewRef);

    // Get the number of customers
    const reviewCount = querySnapshot.size;

    return reviewCount;
  },
  getAverageReview: async () => {
    const querySnapshot = await getDocs(reviewRef);

    // Get the total score and count of reviews
    let totalScore = 0;
    let reviewCount = 0;

    querySnapshot.forEach((doc) => {
      const reviewData = doc.data();
      const score = reviewData.rating;

      // Increment the total score and review count
      totalScore += score;
      reviewCount += 1;
    });

    // Calculate the average review
    const averageReview = reviewCount === 0 ? 0 : totalScore / reviewCount;

    return averageReview;
  },
};

export default service;

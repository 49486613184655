/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import Helmet from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { AppLogo, Button } from "../../components";
import { useAuthentication, useStateReducer } from "../../hooks";
import { classNameBuilder } from "../../utilities";
import { global } from "../../_config";
import "./_styles.scss";

interface State {
  username?: string;
  password?: string;
  submitting?: boolean;
  error?: boolean;
  loading?: boolean;
  errorHighlight?: boolean;
}

export default function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState: any = location.state;
  const [state, setState] = useStateReducer<State>({
    username: "",
    password: "",
    submitting: false,
    error: false,
    loading: true,
    errorHighlight: false,
  });
  const auth = useAuthentication();
  const from = locationState?.from?.pathname ?? "/";

  const handleChange = (prop: string, value: any) =>
    setState({ [prop]: value, errorHighlight: false });

  const handleSignIn = async (isGoogleSignIn: boolean = false) => {
    if (isGoogleSignIn) {
      try {
        const res = await auth?.signInWithGoogle();
        setState({ submitting: false });
        if (res) {
          navigate(from, { replace: true });
        } else {
          setState({ error: true, errorHighlight: true, password: "" });
          setTimeout(() => setState({ error: false }), 1000);
        }
      } catch (error) {
        // Handle Google Sign-In error
        console.error("Google Sign-In error:", error);
      }
    } else {
      // Handle email/password sign-in
      const res = await auth?.signIn(
        state.username ?? "",
        state.password ?? ""
      );
      setState({ submitting: false });
      if (res) {
        navigate(from, { replace: true });
      } else {
        setState({ error: true, errorHighlight: true, password: "" });
        setTimeout(() => setState({ error: false }), 1000);
      }
    }
  };

  const checkForSubmit = (e: any) => {
    if (e.key === "Enter") {
      handleSignIn();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setState({ loading: false });
    }, 1100);
  }, [setState]);

  return (
    <>
      <Helmet>
        <title>Sign In - {global.applicationName}</title>
      </Helmet>
      <div
        className={classNameBuilder(
          "h-app-login",
          state.loading ? "in" : "",
          state.error ? "error" : "",
          state.errorHighlight ? "error-highlight" : ""
        )}
      >
        <AppLogo />
        {/* <div className="credentials">
          <TextField
            focus={!state.username}
            startAdornment={<FontAwesomeIcon icon={faUser} />}
            placeholder="Username or email address"
            value={state.username}
            onChange={(e: any) => handleChange("username", e.target.value)}
            onKeyDown={checkForSubmit}
          />
          <TextField
            focus={state.error}
            type="password"
            placeholder="Password"
            startAdornment={<FontAwesomeIcon icon={faLock} />}
            value={state.password}
            onChange={(e: any) => handleChange("password", e.target.value)}
            onKeyDown={checkForSubmit}
          />
        </div> */}
        <div>
          {/* <a href=""> Forgot Password</a>
          <Button
            text="Sign In"
            primary
            raised
            onClick={() => handleSignIn(false)} // Email/Password sign-in
            working={state.submitting}
          /> */}
          <Button
            text="Sign In with Google"
            primary
            raised
            onClick={() => handleSignIn(true)} // Google Sign-In
            working={state.submitting}
          />
        </div>
      </div>
    </>
  );
}
